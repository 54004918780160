import TemplateContents from './TemplateContents';

import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';
import Transition from './Transition';
import Top from './Top';
import Services from './Services';
import ReferenceDesign from './ReferenceDesign';
import Contact from './Contact';

import TopHero from './TopHero';

export default class extends TemplateContents{
    constructor(param){
        super(param);

        this.pack.common = this;
    }

    init() {
        super.init();
        this.header = this.pack.header = new Header(this.pack);
        this.footer = this.pack.footer = new Footer(this.pack);

        this.hero = this.pack.hero = new TopHero(this.pack);
        this.loadContentful();
        this.checkCookie();
    }

    addScrollTarget(target){
        this.scrollTarget = target;
    }

    removeScrollTarget(){
        this.scrollTarget = undefined;
    }

    addEnterframeTarget(target){
        this.enterframeTarget = target;
    }

    removeEnterframeTarget(){
        this.enterframeTarget = undefined;
    }

    addEvery90framesTarget(target){
        this.every90framesTarget = target;
    }

    removeEvery90framesTarget(){
        this.every90framesTarget = undefined;
    }

    setVars(){
        super.setVars();
        this.enterframeID;
        this.enterframeIgnoreCnt = 0;

        this.pageYOffset = 0;
        this.pastPageYOffset = 0;
        this.scrollYOffset = 0;
        this.pastScrollYOffset = 0;
    }

    setDom(){
        super.setDom();
        this.scrollWrapper = document.querySelector('.scroll-wrapper');
        this.barbaContainer = document.querySelector('.barba-container');
        this.cookieAlert = document.querySelector('#cookie');
    }

    initEvents(){
        super.initEvents();

        this.resizeEvent = navigator.userAgent.match(/(iPhone|iPod|iPad)/) ? 'orientationchange' : 'resize';

        window.addEventListener("DOMContentLoaded", this.DOMContentloadedHandler.bind(this));
        window.addEventListener("load", this.loadedHandler.bind(this));

        window.addEventListener(this.resizeEvent, this.resizeHandler.bind(this), false);
        window.addEventListener('scroll', this.scrollHandler.bind(this),{passive: false});


        this.resizeHandler();
        this.enterframe();
    }

    checkCookie(){
        let cookies = document.cookie; //全てのcookie取り出し
        let cookiesArray = cookies.split(';');  // ;で分割し配列に
        let isAgree;
        for(let c of cookiesArray){
            let cArray = c.split('=');          //=で分割して配列に
            if( cArray[0].indexOf('isCookieAgree') >= 0){
                isAgree = true;
            }
        }

        if(isAgree){
            // this.cookieAlert.classList.add("hide");
            this.cookieAlert.remove();
        }else{
            this.cookieAlert.querySelector(".btn").addEventListener("click", (event) => {
                document.cookie = 'isCookieAgree=true;path=/;expires=31 Dec 2037 00:00:00 GMT';
                // this.cookieAlert.classList.add("hide");
                this.cookieAlert.remove();
            });
        }
    }

    loadContentful() {
        let urlList = this.pack.CONTENTFUL_API_PATH_LIST;
        urlList.push(this.pack.RELEASE_NOTES_API_PATH);        //Release Notesも一緒に読み込む

        let promiseList = [];
        this.jsonBaseList = this.pack.apiJSONList = [];

        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    this.jsonBaseList[i]= res;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                trace("Contentful API loaded", this.jsonBaseList);
                this.loadedContentful = true;
            });
    }

    DOMContentloadedHandler(event){
        this.bindLoadingReadyCompleteHandler = this.loadingReadyCompleteHandler.bind(this);
        this.bindLoadingCompleteHandler = this.loadingCompleteHandler.bind(this);
        this.loading = this.pack.loading = new Loading(this.pack);
        this.loading.addEventListener("readyComplete", this.bindLoadingReadyCompleteHandler);
        this.loading.addEventListener("complete", this.bindLoadingCompleteHandler);
        this.loading.start();
        this.transition = this.pack.transition = new Transition(this.pack);
        // this.initContainer();
        document.body.style.opacity = 1;
    }

    loadedHandler(event){
        window.scrollTo(0, 0);
    }

    loadingReadyCompleteHandler(event){
        this.loading.removeEventListener("readyComplete", this.bindLoadingReadyCompleteHandler);
        this.initContents();
        TweenMax.delayedCall(1.5, ()=>{this.header.showHeaderNav()});
    }


    loadingCompleteHandler(event){
        this.loading.removeEventListener("complete", this.bindLoadingCompleteHandler);
        this.loading = null;
    }


    initContents() {
        window.scrollTo(0, 0);

        let namespace = this.barbaContainer.dataset.namespace;
        this.pack.current = namespace;

        if (namespace === 'top') {
            this.pack.top = new Top(this.pack);
            this.pack.top.start();
        }else if (namespace === 'services') {
            this.pack.services = new Services(this.pack);
            this.pack.services.start();
        }else if (namespace === 'reference_design') {
            this.pack.reference_design = new ReferenceDesign(this.pack);
            this.pack.reference_design.start();
        }else if (namespace === 'contact') {
            this.pack.contact = new Contact(this.pack);
            this.pack.contact.start();
        }

        this.setCurrent();
    }


    setCurrent(){
        this.pack.header.setCurrent();
    }

    start(){

    }

    startTransitionIn(callback){
        this.transition.transitIn(callback);
    }

    startTransitionOut(){
        this.transition.transitOut();
    }

    resetPageYOffset(){
        this.pageYOffset = 0;
        this.scrollYOffset = 0;
    }

    scrollHandler(){
        this.pack.pageYOffset = window.pageYOffset;
        if (this.scrollTarget) this.scrollTarget.scrollHandler();
    }


    enterframe(){
        this.enterframeID = window.requestAnimationFrame(this.enterframe.bind(this));

        if(this.enterframeTarget && this.enterframeTarget.enterframe) this.enterframeTarget.enterframe();

        // this.smoothScroll();

        if(this.enterframeIgnoreCnt%2 === 0){
            this.enterframeThinOut();
            if(this.enterframeTarget) this.enterframeTarget.enterframeThinOut();
            if(this.loading) this.loading.enterframeThinOut();
            if(this.transition) this.transition.enterframeThinOut();
        }

        if(this.enterframeIgnoreCnt % 90 === 0){
            //間引き処理(1 execution per 90 frames)
            this.resizeHandler();
            if(this.every90framesTarget) this.every90framesTarget.enterEvery90Frames();
        }

        //前回のスクロール位置（全ての処理が終わってから代入）
        this.pastPageYOffset = this.pack.pastPageYOffset = this.pack.pageYOffset;

        this.enterframeIgnoreCnt++;
    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}