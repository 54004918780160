import TemplateContents from './TemplateContents';
import TopHero from './TopHero';
import Carousel from './Carousel';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);
        this.resizeEvent = 'resize';
        
        this.initEvents();

        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    setCarousel(){
        this.carousel.setTarget(this.section5);
        this.carousel.setMarginRightPC(true, 2.7777777777);          //2.7777777777vw
        this.carousel.setMarginRightSP(true, 5.3333333333);          //5.3333333333vw
        // this.carousel.setAdjustMinXPC(true, 8.25);                   //8.25vw
        this.carousel.setAdjustMinXSP(true, 8.25);                   //8.25vw
    }

    reset(){
        super.reset();
        this.setVars();
        this.setCarousel();

        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    destruct(){

        super.destruct();
        this.lockScroll = false;
        this.enabledScroll = false;

        document.removeEventListener(this.mousewheelEvent, this.bindWheelHandler);
        document.removeEventListener('touchstart', this.bindTouchStartHandler);
        document.removeEventListener('touchmove', this.bindTouchMoveHandler);
        document.removeEventListener('touchend', this.bindTouchEndHandler);

        this.pack.hero.removeEventListener("openingEnded", this.bindOpeningEndedHeroHandler);
        this.pack.hero.removeEventListener("changedScene", this.bindChangedHeroHandler);

        this.heroSkip.removeEventListener("click", this.bindClickHeroSkipHandler);

        document.removeEventListener('mousemove', this.bindMouseMoveHandler);
        this.heroContents6UL.removeEventListener("mouseover", this.bindMouseOverHeroContents6UL);
        this.heroContents6UL.removeEventListener("mouseout", this.bindMouseOutHeroContents6UL);
        this.heroContents6UL.removeEventListener("click", this.bindClickHeroContents6UL);
        this.heroContents6UL.removeEventListener("touchstart", this.bindTouchStartHeroContents6UL);
        this.heroContents6UL.removeEventListener("touchmove", this.bindTouchMoveHeroContents6UL);
        this.heroContents6UL.removeEventListener("touchend", this.bindTouchEndHeroContents6UL);

        this.heroContents7UL.removeEventListener("mouseover", this.bindMouseOverHeroContents7UL);
        this.heroContents7UL.removeEventListener("mouseout", this.bindMouseOutHeroContents7UL);
        this.heroContents7UL.removeEventListener("click", this.bindClickHeroContents7UL);
        this.heroContents7UL.removeEventListener("touchstart", this.bindTouchStartHeroContents7UL);
        this.heroContents7UL.removeEventListener("touchmove", this.bindTouchMoveHeroContents7UL);
        this.heroContents7UL.removeEventListener("touchend", this.bindTouchEndHeroContents7UL);

        this.localNavLeftBt.removeEventListener("click", this.bindClickLocalNavLeftBtHandler);
        this.localNavRightBt.removeEventListener("click", this.bindClickLocalNavRightBtHandler);

        this.carousel.destruct();

        this.pack.header.showHeader();

        this.pack.common.removeScrollTarget(this);
        this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        this.pack.hero.removeHero(this.heroCanvasContainer);
        this.pack.hero.destruct();
    }

    setVars(){
        super.setVars();

        this.loadedContentful = false;
        this.lockScroll = true;                     //スクロールを無効にするかどうか
        this.enabledScroll = false;
        this.currentHero = 1;
        this.pastHero = this.currentHero;
        this.HERO_SCENE_LEN = 8;

        this.touchStartPoint = 0;
        this.swipeDif = 0;

        this.ourTeamList = [];
        this.ourTeamImgAccessAPIPathList = [];

        this.ourPartnerList = [];
        this.ourPartnerImgAccessAPIPathList = [];

        this.isWaitingBackHeroScene8 = false;

        this.mouseX = 0;
        this.mouseY = 0;
        this.onHeroContents6UL = false;
        this.onHeroContents7UL = false;
        this.heroContents6Current = 1;
        this.heroContents7Current = 1;

        this.isHeroContents6Swiping = false;
        this.isHeroContents7Swiping = false;
        this.heroContents6SwipeStartPoint;
        this.heroContents7SwipeStartPoint;
        this.heroContents6SwipeDif = undefined;
        this.heroContents7SwipeDif = undefined;

    }

    setDom(){
        super.setDom();

        this.heroCanvasContainer = document.querySelector('main.top .hero .canvasContainer');
        this.heroContents = document.querySelectorAll('.hero .common_contents');
        this.heroScroll = document.querySelector('main.top .hero .scroll');
        this.heroCreateAccount = document.querySelector('main.top .hero .create_account');
        this.heroSkip = document.querySelector('main.top .hero .skip');

        //TODO
        this.heroMaskContainer = document.querySelector('main.top .hero .maskContainer');

        this.heroContents6NextPrev = document.querySelector('main.top .hero .contents .contents6 .next_prev_bt');
        this.heroContents7NextPrev = document.querySelector('main.top .hero .contents .contents7 .next_prev_bt');
        this.heroContents6UL = document.querySelector('main.top .hero .contents .contents6 ul');
        this.heroContents7UL = document.querySelector('main.top .hero .contents .contents7 ul');

        this.localNav = document.querySelector('main.top .local_nav');
        this.localNavLeftBt = document.querySelector('main.top .local_nav .left_bt');
        this.localNavRightBt = document.querySelector('main.top .local_nav .right_bt');
        this.localNavNumerator = document.querySelector('main.top .local_nav .current .numerator');

        this.sections = document.querySelectorAll('main.top .section');
        this.releaseNotesContainer = document.querySelector('main.top .section3 .documentation_update');
        this.section5 = document.querySelector('main.top .section5');
        this.ourTeamContainer = document.querySelector('main.top .section5 .items');
        this.ourPartnerContainer = document.querySelector('main.top .section4 .items');

    }

    initEvents(){
        super.initEvents();

        this.mousewheelEvent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';

        this.bindWheelHandler = this.wheelHandler.bind(this);
        this.bindTouchEndHandler = this.touchEndHandler.bind(this);
        this.bindTouchMoveHandler = this.touchMoveHandler.bind(this);
        this.bindTouchStartHandler = this.touchStartHandler.bind(this);
        this.bindOpeningEndedHeroHandler = this.openingEndedHeroHandler.bind(this);
        this.bindChangedHeroHandler = this.changedHeroHandler.bind(this);
        this.bindClickHeroSkipHandler = this.clickHeroSkipHandler.bind(this);
        this.bindMouseOverHeroContents6UL = this.mouseOverHeroContents6UL.bind(this);
        this.bindMouseOutHeroContents6UL = this.mouseOutHeroContents6UL.bind(this);
        this.bindClickHeroContents6UL = this.clickHeroContents6UL.bind(this);
        this.bindTouchStartHeroContents6UL = this.touchStartHeroContents6UL.bind(this);
        this.bindTouchMoveHeroContents6UL = this.touchMoveHeroContents6UL.bind(this);
        this.bindTouchEndHeroContents6UL = this.touchEndHeroContents6UL.bind(this);

        this.bindMouseOverHeroContents7UL = this.mouseOverHeroContents7UL.bind(this);
        this.bindMouseOutHeroContents7UL = this.mouseOutHeroContents7UL.bind(this);
        this.bindClickHeroContents7UL = this.clickHeroContents7UL.bind(this);
        this.bindTouchStartHeroContents7UL = this.touchStartHeroContents7UL.bind(this);
        this.bindTouchMoveHeroContents7UL = this.touchMoveHeroContents7UL.bind(this);
        this.bindTouchEndHeroContents7UL = this.touchEndHeroContents7UL.bind(this);

        this.bindMouseMoveHandler = this.mouseMoveHandler.bind(this);
        this.bindClickLocalNavLeftBtHandler = this.clickLocalNavLeftBtHandler.bind(this);
        this.bindClickLocalNavRightBtHandler = this.clickLocalNavRightBtHandler.bind(this);


        document.addEventListener(this.mousewheelEvent, this.bindWheelHandler, {passive: false});
        document.addEventListener('touchstart', this.bindTouchStartHandler, {passive: false});
        document.addEventListener('touchmove', this.bindTouchMoveHandler, {passive: false});
        document.addEventListener('touchend', this.bindTouchEndHandler, {passive: false});

        this.pack.hero.addEventListener("openingEnded", this.bindOpeningEndedHeroHandler);
        this.pack.hero.addEventListener("changedScene", this.bindChangedHeroHandler);

        this.heroSkip.addEventListener("click", this.bindClickHeroSkipHandler);

        document.addEventListener('mousemove', this.bindMouseMoveHandler, {passive: false});
        this.heroContents6UL.addEventListener("mouseover", this.bindMouseOverHeroContents6UL);
        this.heroContents6UL.addEventListener("mouseout", this.bindMouseOutHeroContents6UL);
        this.heroContents6UL.addEventListener("click", this.bindClickHeroContents6UL);
        this.heroContents6UL.addEventListener("touchstart", this.bindTouchStartHeroContents6UL);
        this.heroContents6UL.addEventListener("touchmove", this.bindTouchMoveHeroContents6UL);
        this.heroContents6UL.addEventListener("touchend", this.bindTouchEndHeroContents6UL);

        this.heroContents7UL.addEventListener("mouseover", this.bindMouseOverHeroContents7UL);
        this.heroContents7UL.addEventListener("mouseout", this.bindMouseOutHeroContents7UL);
        this.heroContents7UL.addEventListener("click", this.bindClickHeroContents7UL);
        this.heroContents7UL.addEventListener("touchstart", this.bindTouchStartHeroContents7UL);
        this.heroContents7UL.addEventListener("touchmove", this.bindTouchMoveHeroContents7UL);
        this.heroContents7UL.addEventListener("touchend", this.bindTouchEndHeroContents7UL);

        this.localNavLeftBt.addEventListener("click", this.bindClickLocalNavLeftBtHandler);
        this.localNavRightBt.addEventListener("click", this.bindClickLocalNavRightBtHandler);

    }

    clickLocalNavLeftBtHandler(event){
        trace("left");
    }

    clickLocalNavRightBtHandler(event){
        trace("right");
    }


    mouseMoveHandler(event){
        this.mouseX = event.clientX;
        this.mouseY = event.clientY;
    }

    mouseOverHeroContents6UL(event){
        if(this.sw < this.pack.BP) return;
        document.body.classList.add("hide_cursor");
        this.heroContents6NextPrev.classList.add("show");
        this.onHeroContents6UL = true;
    }

    mouseOutHeroContents6UL(event){
        if(this.sw < this.pack.BP) return;
        document.body.classList.remove("hide_cursor");
        this.heroContents6NextPrev.classList.remove("show");
        this.onHeroContents6UL = false;
    }

    clickHeroContents6UL(event){
        if(this.sw < this.pack.BP) return;
        if(this.heroContents6NextPrev.classList.contains("prev")){
            this.heroContents6UL.classList.remove("show" + this.heroContents6Current);
            if(this.heroContents6Current != 1) this.heroContents6Current--;
            this.heroContents6UL.classList.add("show" + this.heroContents6Current);
        }else{
            this.heroContents6UL.classList.remove("show" + this.heroContents6Current);
            if(this.heroContents6Current != 6) this.heroContents6Current++;
            this.heroContents6UL.classList.add("show" + this.heroContents6Current);
        }
        this.pack.hero.changeSubScene(6, this.heroContents6Current);
    }

    touchStartHeroContents6UL(event){
        if(this.sw >= this.pack.BP) return;
        this.isHeroContents6Swiping = true;

        let touches = event.changedTouches[0];
        this.heroContents6SwipeStartPoint = touches.clientX;

    }

    touchMoveHeroContents6UL(event){
        if(this.sw >= this.pack.BP) return;
    }

    touchEndHeroContents6UL(event){
        if(this.sw >= this.pack.BP) return;
        let touches = event.changedTouches[0];
        let current = touches.clientX;
        if(current >= this.heroContents6SwipeStartPoint){
            this.heroContents6UL.classList.remove("show" + this.heroContents6Current);
            if(this.heroContents6Current != 1) this.heroContents6Current--;
            this.heroContents6UL.classList.add("show" + this.heroContents6Current);
        }else{
            this.heroContents6UL.classList.remove("show" + this.heroContents6Current);
            if(this.heroContents6Current != 6) this.heroContents6Current++;
            this.heroContents6UL.classList.add("show" + this.heroContents6Current);
        }

        this.pack.hero.changeSubScene(6, this.heroContents6Current);

        this.isHeroContents6Swiping = false;

    }

    mouseOverHeroContents7UL(event){
        if(this.sw < this.pack.BP) return;
        document.body.classList.add("hide_cursor");
        this.heroContents7NextPrev.classList.add("show");
        this.onHeroContents7UL = true;
    }

    mouseOutHeroContents7UL(event){
        if(this.sw < this.pack.BP) return;
        document.body.classList.remove("hide_cursor");
        this.heroContents7NextPrev.classList.remove("show");
        this.onHeroContents7UL = false;
    }

    clickHeroContents7UL(event){
        if(this.sw < this.pack.BP) return;
        if(this.heroContents7NextPrev.classList.contains("prev")){
            this.heroContents7UL.classList.remove("show" + this.heroContents7Current);
            if(this.heroContents7Current != 1) this.heroContents7Current--;
            this.heroContents7UL.classList.add("show" + this.heroContents7Current);
        }else{
            this.heroContents7UL.classList.remove("show" + this.heroContents7Current);
            if(this.heroContents7Current != 3) this.heroContents7Current++;
            this.heroContents7UL.classList.add("show" + this.heroContents7Current);
        }

        this.pack.hero.changeSubScene(7, this.heroContents7Current);
    }

    touchStartHeroContents7UL(event){
        this.isHeroContents7Swiping = true;

        let touches = event.changedTouches[0];
        this.heroContents7SwipeStartPoint = touches.clientX;
    }

    touchMoveHeroContents7UL(event){
        if(this.sw >= this.pack.BP) return;
    }

    touchEndHeroContents7UL(event){
        if(this.sw >= this.pack.BP) return;
        let touches = event.changedTouches[0];
        let current = touches.clientX;
        if(current >= this.heroContents7SwipeStartPoint){
            this.heroContents7UL.classList.remove("show" + this.heroContents7Current);
            if(this.heroContents7Current != 1) this.heroContents7Current--;
            this.heroContents7UL.classList.add("show" + this.heroContents7Current);
        }else{
            this.heroContents7UL.classList.remove("show" + this.heroContents7Current);
            if(this.heroContents7Current != 3) this.heroContents7Current++;
            this.heroContents7UL.classList.add("show" + this.heroContents7Current);
        }

        this.pack.hero.changeSubScene(7, this.heroContents7Current);

        this.isHeroContents7Swiping = false;
    }

    clickHeroSkipHandler(event){
        this.pastHero = this.currentHero;
        this.currentHero = 8;
        this.pack.hero.skipScene();
        this.skipScene();
    }

    openingEndedHeroHandler(event){
        this.enabledScroll = true;
    }

    changedHeroHandler(event){
        this.enabledScroll = true;
        if(this.currentHero === this.HERO_SCENE_LEN) this.lockScroll = false;
    }

    wheelHandler(event) {
        if(this.pack.header.isMenuOpen) return;
        let delta = event.deltaY ? (event.deltaY) : event.wheelDelta ? event.wheelDelta : -(event.detail);
        this.checkSceneChange(event, delta);
    }

    touchStartHandler(event){
        if(this.pack.header.isMenuOpen) return;
        let touches = event.changedTouches[0];
        this.touchStartPoint = touches.clientY;

        if(this.isHeroContents6Swiping) this.heroContents6SwipeDif = 0;
        else if(this.isHeroContents7Swiping) this.heroContents7SwipeDif = 0;
    }

    touchMoveHandler(event) {
        if(this.pack.header.isMenuOpen) return;
        if(this.isWaitingBackHeroScene8) {
            event.preventDefault();
            return;
        }
        let touches = event.changedTouches[0];
        let y = touches.clientY;
        this.swipeDif = Math.abs(y - this.touchStartPoint);

        if(this.isHeroContents6Swiping) this.heroContents6SwipeDif = this.swipeDif;
        else if(this.isHeroContents7Swiping) this.heroContents7SwipeDif = this.swipeDif;

        if (this.lockScroll) event.preventDefault();
    }

    touchEndHandler(event){
        if(this.pack.header.isMenuOpen) return;
        if(!this.touchStartPoint) return;

        let touches = event.changedTouches[0];
        let y = touches.clientY;
        let delta = (y > this.touchStartPoint) ? -1 : 1;

        if(this.heroContents6SwipeDif != undefined) {
            let dif = this.heroContents6SwipeDif;
            this.heroContents6SwipeDif = undefined;
            if(dif < 50) return;            //Y軸の移動量が50px未満だったら遷移キャンセル
        }else if(this.heroContents7SwipeDif != undefined){
            let dif = this.heroContents7SwipeDif;
            this.heroContents7SwipeDif = undefined;
            if(dif < 50) return;            //Y軸の移動量が50px未満だったら遷移キャンセル
        }

        if(y === this.touchStartPoint) return;
        this.checkSceneChange(event, delta);
    }

    checkSceneChange(event, delta){

        if(this.isWaitingBackHeroScene8) {
            event.preventDefault();
            return;
        }

        if (this.lockScroll) {
            window.scrollTo(0, 0);
            event.preventDefault();

            if(!this.enabledScroll) return;
            let isPrev;
            if(delta > 0){
                if(this.currentHero === this.HERO_SCENE_LEN) return;
                this.pastHero = this.currentHero;
                this.currentHero++;
            }else{
                if(this.currentHero === 1) return;
                this.pastHero = this.currentHero;
                this.currentHero--;
                isPrev = true;
            }

            this.enabledScroll = false;
            this.pack.hero.setScene(this.currentHero, isPrev);
            this.setScene(this.currentHero);
            this.changeNumerator(this.pack.addZero(String(this.currentHero)));
        }else{
            if(window.scrollY > 0) return;

            if(delta < 0){
                this.isWaitingBackHeroScene8 = true;
                TweenMax.delayedCall(1, ()=>{
                    this.isWaitingBackHeroScene8 = false;
                    this.lockScroll = true;
                    this.pastHero = this.currentHero;
                    this.currentHero--;
                    this.enabledScroll = false;
                    this.pack.hero.setScene(this.currentHero, true);
                    this.setScene(this.currentHero);
                    this.changeNumerator(this.pack.addZero(String(this.currentHero)));
                });
            }
        }
    }

    resetHeroContents(contents_num){
        this["heroContents" + contents_num + "Current"] = 1;
        let ul = this["heroContents" + contents_num + "UL"];
        for( let i = 1, len = 6; i <= len; i++ ){
            ul.classList.remove("show" + i);
        }
    }

    start(){
        this.pack.hero.addHero(this.heroCanvasContainer);
        this.pack.hero.start();

        TweenMax.delayedCall(.7, ()=>{
            this.heroContents[0].classList.add("show");
        });

        TweenMax.delayedCall(2.3, ()=>{
            this.heroContents[0].classList.add("show");
            this.heroScroll.classList.add("show");
            this.heroCreateAccount.classList.add("show");
        });

        this.setValues();
        this.setOurTeam();
        this.loadOurTeamImages();
        this.setOurPartner();
        this.loadOurPartnerImages();
        // this.pack.setReleaseNotes(this.releaseNotesContainer);
    }

    setValues(){

        let items = this.pack.apiJSONList[0].items;

        for( let i = 0, len = items.length; i < len; i++ ){
            let item = items[i].fields;

            let title = this.pack.getTitle(item);
            let description = this.pack.getDescription(item);
            let url = item.url;
            let imgAccessAPIPath = this.pack.getContentfulAPIImgAccessPath(item.image.sys.id);

            this.ourTeamList[i] = {title:title, description:description, url:url};
            this.ourTeamImgAccessAPIPathList[i] = imgAccessAPIPath;
        }

        items = this.pack.apiJSONList[1].items;
        for( let i = 0, len = items.length; i < len; i++ ){
            let item = items[i].fields;
            let name = item.companyName;
            // let description = this.pack.getDescription(item);
            let description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget porttitor urna.";       //TODO
            let url = item.url;
            let imgAccessAPIPath = this.pack.getContentfulAPIImgAccessPath(item.logoImage.sys.id);

            this.ourPartnerList[i] = {name:name, description:description, url:url};
            this.ourPartnerImgAccessAPIPathList[i] = imgAccessAPIPath;
        }
    }

    getOurTeamHTML(value){
        /* pug src
            li.item.carousel_item
                a.white(href="" target="_blank")
                    img(src="/assets/img/_post/top/team_member.jpg" alt="")
                    .name About Dummy Name
                    p.txt Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget porttitor urna.
         */

        let title = value.title;
        let description = value.description;
        let url = value.url;

        return `
            <li class="item carousel_item">
                <a class="white" href="${url}" target="_blank">
                    <img src="/assets/img/top/default.jpg" alt="${title}">
                    <div class="name">${title}</div>
                    <p class="txt">${description}</p>
                </a>
            </li>
        
        `;
    }

    getOurPartnerHTML(value){
        /*
            li.item
                a.white(href="" target="_blank")
                    div.img
                        img(src="/assets/img/_post/top/partner_logo.png" alt="")
                    p.txt Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget porttitor urna.
         */

        let name = value.name;
        let description = value.description;
        let url = value.url;

        return `
            <li class="item">
                <a class="white" href="${url}" target="_blank">
                    <div class="img">
                        <img src="" alt="${name}">
                    </div>
                    <p class="txt">${description}</p>
                </a>
            </li>
        `;
    }

    setOurTeam() {
        let src = "";
        for( let i = 0, len = this.ourTeamList.length; i < len; i++ ){
            let item = this.ourTeamList[i];
            let html = this.getOurTeamHTML(item);
            src += html;
        }

        this.ourTeamContainer.innerHTML = src;

        this.carousel = new Carousel(this.pack);
        this.setCarousel();
    }

    setOurPartner() {
        let src = "";
        for( let i = 0, len = this.ourPartnerList.length; i < len; i++ ){
            let item = this.ourPartnerList[i];
            let html = this.getOurPartnerHTML(item);
            src += html;
        }

        this.ourPartnerContainer.innerHTML = src;
    }

    loadOurTeamImages() {
        let urlList = this.ourTeamImgAccessAPIPathList;
        let promiseList = [];
        this.ourTeamImgPathList = [];

        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    this.ourTeamImgPathList[i]= res;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                trace("Our team img path loaded", this.ourTeamImgPathList);
                this.setOurTeamImgs();
            });
    }

    loadOurPartnerImages() {
        let urlList = this.ourPartnerImgAccessAPIPathList;
        let promiseList = [];
        this.ourPartnerImgPathList = [];

        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    this.ourPartnerImgPathList[i]= res;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                // trace("Our partner img path loaded", this.ourPartnerImgPathList);
                this.setOurPartnerImgs();
            });
    }

    setOurTeamImgs() {
        let protocol = location.protocol;
        let imgs = document.querySelectorAll('main.top .section5 .items .item img');
        for( let i = 0, len = this.ourTeamImgPathList.length; i < len; i++ ){
            let imgURL = protocol + this.ourTeamImgPathList[i].fields.file.url;
            if(imgURL) imgs[i].src = imgURL;
        }
    }

    setOurPartnerImgs() {
        let protocol = location.protocol;
        let imgs = document.querySelectorAll('main.top .section4 .items .item img');
        for( let i = 0, len = this.ourPartnerImgPathList.length; i < len; i++ ){
            let imgURL = protocol + this.ourPartnerImgPathList[i].fields.file.url;
            imgs[i].src = imgURL;
        }
    }

    setScene(id){
        this.heroContents[this.pastHero-1].classList.remove("show");
        this.heroContents[this.currentHero-1].classList.add("show");

        if(id === 1) {
            this.heroCreateAccount.classList.remove("hide");
            this.pack.header.showHeader();
            this.heroSkip.classList.remove("show");
            this.heroScroll.classList.add("show");
            this.localNav.classList.remove("show");
        }else if(id === 2) {
            this.heroCreateAccount.classList.add("hide");
            this.pack.header.hideHeader();
            this.heroSkip.classList.add("show");
            this.heroScroll.classList.remove("show");
            this.localNav.classList.add("show");
        }
    }

    skipScene(){
        this.lockScroll = false;

        this.heroContents[this.pastHero-1].classList.remove("show");
        this.heroContents[this.currentHero-1].classList.add("show");
        this.heroCreateAccount.classList.add("hide");
        this.pack.header.hideHeader();
        this.heroSkip.classList.add("show");
        this.heroScroll.classList.remove("show");
        this.changeNumerator(this.pack.addZero(String(this.currentHero)));
        this.pack.anchorScroll(this.sh);
    }

    changeNumerator(value){
        this.localNavNumerator.innerText = value;
        // this.heroNumerator.classList.add("blink");
        TweenMax.delayedCall(.3, ()=>{
            // this.heroNumerator.classList.remove("blink");
        });
    }

    scrollHandler(){
        this.checkSections();
    }

    checkSections(){
        for( let i = 0, len = this.sections.length; i < len; i++ ){
            let item = this.sections[i];
            if(!item.classList.contains("show")){
                let top = item.getBoundingClientRect().top;
                if(top < 100) item.classList.add("show");
            }
        }
    }

    enterframe(){

    }

    enterframeThinOut(){
        this.pack.hero.enterframeThinOut();

        if(this.onHeroContents6UL){
            this.heroContents6NextPrev.style.left = this.mouseX + "px";
            this.heroContents6NextPrev.style.top = this.mouseY + "px";
            let ulWidthH = this.heroContents6UL.clientWidth / 2;
            let ulX = this.heroContents6UL.getBoundingClientRect().left;
            if(this.mouseX < ulX + ulWidthH) this.heroContents6NextPrev.classList.add("prev");
            else this.heroContents6NextPrev.classList.remove("prev");
        }

        if(this.onHeroContents7UL){
            this.heroContents7NextPrev.style.left = this.mouseX + "px";
            this.heroContents7NextPrev.style.top = this.mouseY + "px";
            let ulWidthH = this.heroContents7UL.clientWidth / 2;
            let ulX = this.heroContents7UL.getBoundingClientRect().left;
            if(this.mouseX < ulX + ulWidthH) this.heroContents7NextPrev.classList.add("prev");
            else this.heroContents7NextPrev.classList.remove("prev");
        }
    }

    executeResize() {
        super.executeResize();

        this.heroSkip.style.top = this.sh + "px";
    }
}