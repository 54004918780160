import "@babel/polyfill";

import "../scss/common.scss";
import "../scss/top.scss";
import "../scss/subpage.scss";
import "../scss/services.scss";
import "../scss/reference_design.scss";
import "../scss/contact.scss";

import Config from "./Config";
import Common from './Common';

require('./libs/TweenMax.min.js');
require('./libs/daijima/trace.js');
require('./libs/daijima/requestanimationframe.js');

let packName = "TIER4";
let pack = new Config(window, packName);

//CSS Modulesとして使用する場合の例
// import * as styles from "../scss/common.scss";

new Common(pack);

require('./barba-custom.js');