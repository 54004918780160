import Platform from './Subpage';
// let MarkdownIt = require('markdown-it');

export default class extends Platform{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();

        this.section1Container = document.querySelector('main.services .sections .section1 .section_child');
        this.section2Container = document.querySelector('main.services .sections .section2 .section_child');
        this.section3Container = document.querySelector('main.services .sections .section3 .section_child');

        this.movie = document.querySelector('main.services .movie');
        this.movieChild = document.querySelector('main.services .movie .movie_child');

    }

    initEvents(){
        super.initEvents();
    }

    start(){
        super.start();
        // this.setValues();
        // this.setSection(1);
        // this.setSection(2);
        // this.setSection(3);
        this.sectionH3List = document.querySelectorAll('main.subpage .sections .section h3');
        // this.pack.setReleaseNotes(this.releaseNotesContainer);
    }

    setValues(){
        let items = this.pack.apiJSONList[3].items;
        for( let i = 0, len = items.length; i < len; i++ ){
            let item = items[i];
            let fields = item.fields;
            let relatedID = item.sys.id;
            let mediaSysID = this.pack.getData(fields, "media").sys.id;
            let description = this.pack.getDescription(fields);
            let title = this.pack.getTitle(fields);
            this.detailList[i] = {title:title, relatedID:relatedID, mediaSysID:mediaSysID, description:description};
        }

        items = this.pack.apiJSONList[2].items;
        this.sectionChildLen = items.length;
        for( let i = 0, len = this.sectionChildLen; i < len; i++ ){
            let item = items[i].fields;
            let displayOrder = item.displayOrder;
            let contentsID;
            let details = item.details;
            let mediaIDList = [];

            if(item.devopsProcessName === "TOOLS") contentsID = 1;
            else if(item.devopsProcessName === "SIMULATORS") contentsID = 2;
            else if(item.devopsProcessName === "DATA PLATFORM") contentsID = 3;

            let tit = item.toolAboutText;
            let description = this.pack.getData(item, "toolDescription");

            for( let j = 0; j < details.length; j++ ){
                let detail  = details[j];
                let id = detail.sys.id;
                mediaIDList.push(id);
            }

            // trace(item);
            // trace(displayOrder, contentsID, tit, description);
            this["contents" + contentsID + "List"][displayOrder - 1] = {tit:tit, description:description, mediaIDList:mediaIDList};
        }

        // trace(this.contents1List);
        // trace(this.contents2List);
        // trace(this.contents3List);
    }

    scrollHandler(){
        super.scrollHandler();
        this.checkMovie();
    }

    checkMovie(){
        let height = 500;

        let ratio = Math.min(this.pack.pageYOffset / height, 1);
        let invRatio = 1 - Math.min(this.pack.pageYOffset / height, 1);
        let borderRadius, width;
        if(this.sw >= this.pack.BP){
            borderRadius = 2.2222222222 * invRatio;
            width = 89 + 11 * ratio;
        }else{
            borderRadius = 4.2666666667 * invRatio;
            width = 89 + 11 * ratio;
        }

        this.movieChild.style.borderRadius = borderRadius + "rem";
        this.movieChild.style.width = width + "vw";
    }

    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}