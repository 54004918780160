import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.isMenuOpen = false;
    }

    setDom(){
        super.setDom();

        this.grill = document.querySelector('header .grill');
        this.menu = document.querySelector('header .menu');
        this.menuBG = document.querySelector('header .menu .bg');

        this.h2 = document.querySelector('header h2');
        this.headerNav = document.querySelector('header .header_nav');
        this.headerNavList = document.querySelectorAll('header .header_nav ul li');

        this.menuMainNav = document.querySelectorAll('header .menu .main_nav li');
        this.menuMainNavTxt = document.querySelectorAll('header .menu .main_nav .main');
        this.menuMainNavSubTxt = [];
        for( let i = 0, len = this.menuMainNav.length; i < len; i++ ){
            this.menuMainNavSubTxt.push(this.menuMainNav[i].querySelectorAll('.sub a'));
        }

        this.menuLangNav = document.querySelector('header .menu .lang_nav');
        this.menuNewsletter = document.querySelector('header .menu .newsletter');

        this.menuSubNavSocials = document.querySelectorAll('header .menu .sub_nav .social li');
        this.menuSubNavOthers = document.querySelectorAll('header .menu .sub_nav .others li');
        this.menuCopyright = document.querySelector('header .menu .cp');
    }

    initEvents(){
        super.initEvents();

        //アンカーリンクの設定
        for( let i = 0, len = this.menuMainNavSubTxt.length; i < len; i++ ){
            let items = this.menuMainNavSubTxt[i];
            for( let j = 0; j < items.length; j++ ){
                let item = items[j];
                item.addEventListener("click", (event)=>{
                    let url = location.protocol + '//' + location.host + location.pathname;
                    let extract_hash = event.currentTarget.href.replace(/#.*$/,"");

                    if (extract_hash === url ){
                        this.closeMenu();
                    }
                });
            }
        }

        this.grill.addEventListener("click", this.clickGrillHandler.bind(this));
        this.grill.addEventListener("mouseover", (event)=>{
            this.grill.classList.add("hover");
        });

        this.grill.addEventListener("mouseout", (event)=>{
            this.grill.classList.remove("hover");
        });
    }

    clickGrillHandler(){
        if(this.menu.classList.contains("show")){
            this.closeMenu();
        }else{
            this.openMenu();
        }
    }

    closeMenu() {
        this.isMenuOpen = false;
        document.documentElement.classList.remove("lock");
        this.grill.classList.remove("close");
        this.menuBG.classList.remove("open");
        this.menu.classList.remove("open");

        for( let i = 0, len = this.menuMainNav.length; i < len; i++ ){
            let item = this.menuMainNav[i];
            item.style.transitionDelay = "0s";
            item.classList.add("close");
        }

        for( let i = 0, len = this.menuMainNavSubTxt.length; i < len; i++ ){
            let items = this.menuMainNavSubTxt[i];
            for( let j = 0; j < items.length; j++ ){
                let item = items[j];
                item.style.transitionDelay = "0s";
                item.classList.remove("open");
            }
        }

        this.menuLangNav.style.transitionDelay = "0s";
        this.menuLangNav.classList.remove("open");

        this.menuNewsletter.style.transitionDelay = "0s";
        this.menuNewsletter.classList.remove("open");

        for( let i = 0, len = this.menuSubNavSocials.length; i < len; i++ ){
            let item = this.menuSubNavSocials[i];
            item.style.transitionDelay = "0s";
            item.classList.remove("open");
        }

        for( let i = 0, len = this.menuSubNavOthers.length; i < len; i++ ){
            let item = this.menuSubNavOthers[i];
            item.style.transitionDelay = "0s";
            item.classList.remove("open");
        }

        this.menuCopyright.style.transitionDelay = "0s";
        this.menuCopyright.classList.remove("open");

        TweenMax.delayedCall(.7, ()=>{
            this.menu.classList.remove("show");
            for( let i = 0, len = this.menuMainNav.length; i < len; i++ ){
                let item = this.menuMainNav[i];
                item.style.transitionDelay = "0s";
                item.classList.remove("close");
                item.classList.remove("open");
            }

            for( let i = 0, len = this.menuMainNavTxt.length; i < len; i++ ){
                let item = this.menuMainNavTxt[i];
                item.style.transitionDelay = "0s";
                item.classList.remove("open");
            }
        });
    }

    openMenu() {
        this.isMenuOpen = true;
        document.documentElement.classList.add("lock");
        this.menu.classList.add("show");
        TweenMax.delayedCall(0.1, ()=>{
            this.grill.classList.add("close");
            this.menuBG.classList.add("open");
            this.menu.classList.add("open");
            let delay = .12;

            for( let i = 0, len = this.menuMainNav.length; i < len; i++ ){
                let item = this.menuMainNav[i];
                item.style.transitionDelay = (i + 1) * delay + "s";
                item.classList.add("open");
            }

            for( let i = 0, len = this.menuMainNavTxt.length; i < len; i++ ){
                let item = this.menuMainNavTxt[i];
                // item.style.transitionDelay = .15 + i * delay + "s";
                item.style.transitionDelay = .15 + i * delay + "s";
                item.classList.add("open");
            }

            for( let i = 0, len = this.menuMainNavSubTxt.length; i < len; i++ ){
                let items = this.menuMainNavSubTxt[i];
                for( let j = 0; j < items.length; j++ ){
                    let item = items[j];
                    item.style.transitionDelay = .35 + i * delay + j * delay + "s";
                    // item.style.transitionDelay = .2 + i * delay + j * delay + "s";
                    item.classList.add("open");
                }
            }

            this.menuLangNav.style.transitionDelay = "400ms";
            this.menuLangNav.classList.add("open");


            this.menuNewsletter.style.transitionDelay = "700ms";
            this.menuNewsletter.classList.add("open");

            for( let i = 0, len = this.menuSubNavSocials.length; i < len; i++ ){
                let item = this.menuSubNavSocials[i];
                item.style.transitionDelay = .75 + i * .02 + "s";
                item.classList.add("open");
            }

            for( let i = 0, len = this.menuSubNavOthers.length; i < len; i++ ){
                let item = this.menuSubNavOthers[i];
                item.style.transitionDelay = .85 + i * .05 + "s";
                item.classList.add("open");
            }

            this.menuCopyright.style.transitionDelay = "1100ms";
            this.menuCopyright.classList.add("open");
        });
    }

    setCurrent(){
        let namespace = this.pack.current;
        let currentID;

        for( let i = 0, len = this.menuMainNav.length; i < len; i++ ){
            let item = this.menuMainNav[i];
            item.classList.remove("current");
        }

        for( let i = 0, len = this.headerNavList.length; i < len; i++ ){
            let item = this.headerNavList[i];
            item.classList.remove("current");
        }

        if(namespace === 'top') currentID = -1;
        else if(namespace === 'services') currentID = 0;
        else if(namespace === 'reference_design') currentID = 1;
        else if(namespace === 'contact') currentID = 2;
        else currentID = -1;

        this.menuMainNav[currentID + 1].classList.add("current");

        if(currentID === -1) return;
        this.headerNavList[currentID].classList.add("current");
    }

    hideHeader(){
        this.h2.classList.add("hide");
        this.headerNav.classList.add("hide");
    }

    showHeader(){
        this.h2.classList.remove("hide");
        this.headerNav.classList.remove("hide");
    }

    showHeaderNav(){
        for( let i = 0, len = this.headerNavList.length; i < len; i++ ){
            let item = this.headerNavList[i];
            TweenMax.delayedCall(i * .2, ()=>{
                item.classList.add("show");
                item.classList.add("play");
            });
        }

        TweenMax.delayedCall(1, ()=>{
            for( let i = 0, len = this.headerNavList.length; i < len; i++ ){
                let item = this.headerNavList[i];
                item.classList.remove("play");
            }
        });
    }

    start(){

    }

    closeMenuExternal(){
        if(this.menu.classList.contains("show")){
            this.closeMenu();
        }
    }


    scrollHandler(){

    }

    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}