import Platform from './Subpage';
import Carousel from "./Carousel";

export default class extends Platform{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();

        this.section1Container = document.querySelector('main.reference_design .sections .section1 .section_child');
        this.section2Container = document.querySelector('main.reference_design .sections .section2 .section_child');

        this.section1Chunks = document.querySelectorAll('main.reference_design .section1 .cont .right .chunk');
        this.section1Imgs = document.querySelectorAll('main.reference_design .section1 .cont .left picture');

        this.section2 = document.querySelector('main.reference_design .section2');
        this.section3 = document.querySelector('main.reference_design .section3');

        // trace(this.section1Chunks);
        // trace(this.section1Imgs);

        // this.releaseNotesContainer = document.querySelector('main.reference_design  .documentation_update');
    }

    initEvents(){
        super.initEvents();
    }

    start(){
        super.start();

        this.carousel = new Carousel(this.pack);
        this.carousel2 = new Carousel(this.pack);
        this.setCarousel();
        this.setCarousel2();

        // this.setValues();
        // this.setSection(1);
        // this.setSection(2);
        this.sectionH3List = document.querySelectorAll('main.subpage .sections .section1 h3');
        // this.pack.setReleaseNotes(this.releaseNotesContainer);
    }

    setCarousel(){
        this.carousel.setOnlySP();
        this.carousel.setTarget(this.section2);
        this.carousel.setMarginRightSP(true, 5.3333333333);          //5.3333333333vw
        this.carousel.setAdjustMinXSP(true, 8.25);                   //8.25vw
    }

    setCarousel2(){
        this.carousel2.setOnlySP();
        this.carousel2.setTarget(this.section3);
        this.carousel2.setMarginRightSP(true, 5.3333333333);          //5.3333333333vw
        this.carousel2.setAdjustMinXSP(true, 8.25);                   //8.25vw
    }

    setValues(){
        let items = this.pack.apiJSONList[5].items;
        for( let i = 0, len = items.length; i < len; i++ ){
            let item = items[i];
            let fields = item.fields;
            let relatedID = item.sys.id;
            let mediaSysID = this.pack.getData(fields, "media").sys.id;
            let description = this.pack.getDescription(fields);
            let title = this.pack.getTitle(fields);
            this.detailList[i] = {title:title, relatedID:relatedID, mediaSysID:mediaSysID, description:description};
        }

        items = this.pack.apiJSONList[4].items;
        this.sectionChildLen = items.length;
        for( let i = 0, len = this.sectionChildLen; i < len; i++ ){
            let item = items[i].fields;
            let displayOrder = item.displayOrder;
            let contentsID;
            let details = item.details;
            let mediaIDList = [];

            if(item.devopsProcessName === "FLEET MANAGEMEN SYSTEM") contentsID = 1;
            else if(item.devopsProcessName === "REMOTE SUPPORT SYSTEM") contentsID = 2;

            let tit = item.toolAboutText;
            let description = this.pack.getData(item, "toolDescription");

            for( let j = 0; j < details.length; j++ ){
                let detail  = details[j];
                let id = detail.sys.id;
                mediaIDList.push(id);
            }

            this["contents" + contentsID + "List"][displayOrder - 1] = {tit:tit, description:description, mediaIDList:mediaIDList};
        }
    }

    checkSection1(){
        let current = -1;
        for( let i = 0, len = this.section1Chunks.length; i < len; i++ ){
            let item = this.section1Chunks[i];
            if(item.getBoundingClientRect().top < this.sh - 200){
                current = i;
            }
        }

        if(current < 0) return;
        this.resizeHandler();

        for( let i = 0, len = this.section1Imgs.length; i < len; i++ ){
            let item = this.section1Imgs[i];
            let chunk = this.section1Imgs[i];
            item.classList.remove("show");
            chunk.classList.remove("clearify");
        }

        this.section1Imgs[current].classList.add("show");
        this.section1Chunks[current].classList.add("clearify");
    }

    scrollHandler(){
        super.scrollHandler();
        this.checkSection1();
    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}