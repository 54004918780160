import TemplateContents from './TemplateContents';
import LoadingView from './LoadingView';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();
        this.pack.view = new LoadingView(this.pack);

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.loaded = false;
        this.per = 0;
        this.PI = Math.PI;
        this.defStrokeDashoffsetPC = 242 * this.PI;
        this.defStrokeDashoffsetSP = 160 * this.PI;
    }

    setDom(){
        super.setDom();

        this.loading = document.querySelector('#loading');
    }

    initEvents(){
        super.initEvents();
    }

    start(){
        this.loading.classList.add("show");
    }

    completeLoading(){
        this.pack.view.completeLoading(this, this.callback, this.callback2);
    }

    callback(){
        this.dispatchEvent("readyComplete");
    }

    callback2(){
        this.loading.remove();
        this.dispatchEvent("complete");
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){
        this.pack.view.enterframeThinOut();
        if(this.loaded) return;
        this.per += 3;
        if(this.per >= 100) this.per = 100;


        if(this.per >= 100 && this.pack.common.loadedContentful && this.pack.hero.loaded){
            this.loaded = true;
            this.completeLoading();
        }
    }

    executeResize() {
        super.executeResize();
    }
}