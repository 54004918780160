import TemplateContents from './TemplateContents';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { LinearFilter } from 'three/src/constants'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import Stats from 'three/examples/jsm/libs/stats.module';
import GUI from 'lil-gui';

//Lights
const cameraTargetX = 4.6, cameraTargetY = 2.7, cameraTargetZ = -9, cameraLookAtTargetX = -2.4, cameraLookAtTargetY = -.6, cameraLookAtTargetZ = 4.5,
    ambientIntensity = 0.7,
    ambientColor = 0xEBF5FF,
    directionalIntensity = 0.3,
    directionalColor = 0xFFFFFF,
    directionalLightX = 0.5,
    directionalLightY = 0,
    directionalLightZ = 0.866,
    roadColor = 0x060e39,
    groundColor = 0x101741;

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {

        this.sw, this.sh;
        this.enterframeID;

        this.setVars();
        this.setDom();
        this.bindResizeHandler = this.resizeHandler.bind(this);
        this.hasTouch = ("ontouchstart" in window);
        // this.resizeEvent = this.hasTouch ? 'orientationchange' : 'resize';
        this.resizeEvent = 'resize';

        this.initEvents();

        // this.devicePixelRatio = window.devicePixelRatio || 1;
        // this.devicePixelRatio = Math.min(2, this.devicePixelRatio);

        this.initShader();
        this.initWebGL();
        this.load();

        if(this.enabledStats) this.setStats();
        if(this.enabledGUI) this.setGUI();
    }

    reset(){
        // super.reset();
        // this.setVars();
    }

    destruct(){
        super.destruct();

        this.enabledUpdate = true;

        this.camera.position.x = this.defCameraPosition.x;
        this.camera.position.y = this.defCameraPosition.y;
        this.camera.position.z = this.defCameraPosition.z;

        this.currentTargetPosition = new THREE.Vector3(0, 0, 0);
        this.currentLookatTargetPosition = new THREE.Vector3(0, 0, 0);

        this.currentScene = 1;

        this.currentWheelSpeed = this.wheelSpeed;

        TweenMax.to(this.canvas, 1, {opacity:1, ease:Quad.easeOut});
        TweenMax.to(this.directionalLight, 1, {intensity:directionalIntensity, ease:Quad.easeOut});

        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

/*            if(anime.name === "mainRout"){
                action.paused = false;
                mixer.setTime(0);
                action.reset().play();
                action.paused = true;
            }else if(anime.name === "dashedRout"){
                action.paused = false;
                mixer.setTime(0);
                action.reset().play();
                action.paused = true;
            }*/
        }
    }

    setVars(){
        super.setVars();

        this.enabledUpdate = true;
        this.enabledOrbitControls = false;
        this.enabledStats = false;
        this.enabledGUI = true;

        this.enabledChangeScene = false;

        this.ORANGE_COLOR = {r:245/255, g:158/255, b:46/255};      //#F59E2E
        this.DEF_COLOR = {r:0, g:.5, b:1};

        this.GLB_ASSET_PATH = '/assets/glb/';
        this.POINT_IMG_PATH = this.GLB_ASSET_PATH + '/point/point.png';
        this.ENV_IMG_PATH = this.GLB_ASSET_PATH + '/env/env_white.jpg';

        this.BAK_BUILDING_IMG_PATH = this.GLB_ASSET_PATH + '/bake/tex_building_col.png';
        this.BAK_GROUND_IMG_PATH = this.GLB_ASSET_PATH + '/bake/tex_ground_col.png';
        this.BAK_ROAD_IMG_PATH = this.GLB_ASSET_PATH + '/bake/tex_road_col.png';
        this.BAK_SHADOW_IMG_PATH = this.GLB_ASSET_PATH + '/bake/bake_shadow_alpha_cc.png';

        this.currentTargetPosition = new THREE.Vector3(0, 0, 0);
        this.currentLookatTargetPosition = new THREE.Vector3(0, 0, 0);

        this.currentCarPosVector;
        this.currentScene = 1;

        this.defRoadColor;
        this.defGroundColor;

        this.content;
        this.animeList = [];
        this.clock = new THREE.Clock();

        this.wheelSpeed = 15;
        this.currentWheelSpeed = this.wheelSpeed;
        this.wheelsDeg = 0;

        this.CAR_WIRE_CLONE_LEN = 10;
        this.HUMAN_CLONE_LEN = 6;
        this.CORN_CLONE_LEN = 8;
        this.TRAFFIC_LIGHT_CLONE_LEN = 3;
        this.carWireClones = [];
        this.humanClones = [];
        this.cornClones = [];
        this.trafficLightClones = [];

        this.mouseStage = new THREE.Vector2();

        this.SOLID_ROAD_COLOR = 0xA6A8AB;
        this.SOLID_GROUND_COLOR = 0xBABABA;
        this.POINTS_ROAD_COLOR = 0x001142;
        this.POINTS_GROUND_COLOR = 0x0A2064;

        this.SCENE4_7_CAMERA_INFO = {
            targetX:-5,
            targetY:48,
            targetZ:58,
            lookAtTargetX:3,
            lookAtTargetY:0,
            lookAtTargetZ:-2.3
        };

        this.carShadowY = .05;
    }

    setDom(){
        super.setDom();

        this.canvas = document.createElement("canvas");
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.canvas.width = this.width;
        this.canvas.height = this.height;
    }

    addHero(container){
        container.appendChild(this.canvas);
    }

    removeHero(container){
        this.canvas.remove();
    }

    initEvents(){
        super.initEvents();

        this.bindPointermoveHandler = this.pointerMoveHandler.bind(this);
        //マウスオーバーとドラッグ処理
        this.canvas.addEventListener(this.pack.hasTouch ? 'touchmove' : 'mousemove', this.bindPointermoveHandler,{passive : false});
    }

    //for debug
    setGUI(){
        let sc = this;
        const gui = new GUI();
        const PROPS = {
            'cameraTargetX': cameraTargetX,
            'cameraTargetY': cameraTargetY,
            'cameraTargetZ': cameraTargetZ,
            'cameraLookAtTargetX': cameraLookAtTargetX,
            'cameraLookAtTargetY': cameraLookAtTargetY,
            'cameraLookAtTargetZ': cameraLookAtTargetZ,
            'ambientLight' : ambientColor,
            'ambientIntensity' : ambientIntensity,
            'directionalLight' : directionalColor,
            'directionalIntensity' : directionalIntensity,
            'directionalX' : directionalLightX,
            'directionalY' : directionalLightY,
            'directionalZ' : directionalLightY,
            'roadColor' : roadColor,
            'groundColor' : groundColor,
        };

        gui.width = 350;
        gui.left = 0;
        gui.domElement.style.zIndex = 2000;
        gui.close();

        window.gv = ()=>{
            let tx = this.carCameraTarget.position.x;
            let ty = this.carCameraTarget.position.y;
            let tz = this.carCameraTarget.position.z;
            let ltx = this.cameraLookAtTarget.position.x;
            let lty = this.cameraLookAtTarget.position.y;
            let ltz = this.cameraLookAtTarget.position.z;
            let message = `cameraTargetX = ${tx}, cameraTargetY = ${ty}, cameraTargetZ = ${tz}, cameraLookAtTargetX = ${ltx}, cameraLookAtTargetY = ${lty}, cameraLookAtTargetZ = ${ltz}, 
            this.carCameraTarget.position.x = ${tx}, this.carCameraTarget.position.y = ${ty}, this.carCameraTarget.position.z = ${tz}, this.cameraLookAtTarget.position.x = ${ltx}, this.cameraLookAtTarget.position.y = ${lty}, this.cameraLookAtTarget.position.z = ${ltz};`;
            return message;
        };

        let maxCameraValue = 150;
        gui.add(PROPS, 'cameraTargetX', -maxCameraValue, maxCameraValue).onChange(() => {
            this.carCameraTarget.position.x = PROPS.cameraTargetX;
        });

        gui.add(PROPS, 'cameraTargetY', -maxCameraValue, maxCameraValue).onChange(() => {
            this.carCameraTarget.position.y = PROPS.cameraTargetY;
        });

        gui.add(PROPS, 'cameraTargetZ', -maxCameraValue, maxCameraValue).onChange(() => {
            this.carCameraTarget.position.z = PROPS.cameraTargetZ;
        });

        gui.add(PROPS, 'cameraLookAtTargetX', -maxCameraValue, maxCameraValue).onChange(() => {
            this.cameraLookAtTarget.position.x = PROPS.cameraLookAtTargetX;
        });

        gui.add(PROPS, 'cameraLookAtTargetY', -maxCameraValue, maxCameraValue).onChange(() => {
            this.cameraLookAtTarget.position.y = PROPS.cameraLookAtTargetY;
        });

        gui.add(PROPS, 'cameraLookAtTargetZ', -maxCameraValue, maxCameraValue).onChange(() => {
            this.cameraLookAtTarget.position.z = PROPS.cameraLookAtTargetZ;
        });

        gui.addColor(PROPS, 'ambientLight').onChange(function(){
            sc.ambientLight.color.set(PROPS.ambientLight);
        });

        gui.add(PROPS, 'ambientIntensity', 0, 1).onChange(function(){
            sc.ambientLight.intensity = PROPS.ambientIntensity;
        });

        gui.addColor(PROPS, 'directionalLight').onChange(function(){
            sc.directionalLight.color.set(PROPS.directionalLight);
        });

        gui.add(PROPS, 'directionalIntensity', 0, 1).onChange(function(){
            sc.directionalLight.intensity = PROPS.directionalIntensity;
        });

        gui.add(PROPS, 'directionalX', -Math.PI, Math.PI).onChange(() => {
            this.directionalLight.position.x = PROPS.directionalX;
        });

        gui.add(PROPS, 'directionalY', -Math.PI, Math.PI).onChange(() => {
            this.directionalLight.position.y = PROPS.directionalY;
        });

        gui.add(PROPS, 'directionalZ', -Math.PI, Math.PI).onChange(() => {
            this.directionalLight.position.z = PROPS.directionalZ;
        });

        gui.addColor(PROPS, 'roadColor').onChange(()=>{
            this.road.material.color.set(PROPS.roadColor);
        });

        gui.addColor(PROPS, 'groundColor').onChange(()=>{
            this.ground.material.color.set(PROPS.groundColor);
        });
    }

    setStats(){
        this.stats = Stats()
        document.body.appendChild(this.stats.dom)
    }

    pointerMoveHandler(event){
        let x, y;
        let w = this.sw;
        let h = this.sh;

        if(event.type.indexOf('touch') == 0) {
            let touches = event.changedTouches[0];
            x = touches.clientX;
            y = touches.clientY;
        }else{
            x = event.clientX;
            y = event.clientY;
        }

        this.mouseStage.x = x - (w / 2);
        this.mouseStage.y = -( y ) + (h / 2);
    }

    initShader() {
        this.vertexShaderSrc = `
        
        `;

        this.fragmentShaderSrc = `
        
        `;
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : true,
            antialias : true
        });
        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.width, this.height);
        this.renderer.autoClear = false;


        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color( 0xE8FAFE );


        //Camera
        this.fieldOfView = 45;
        this.defCameraPosition = {};
        this.defCameraPosition.x = 192.3904571533203;
        this.defCameraPosition.y = 300;
        this.defCameraPosition.z = -135.74269104003906;
        this.camera = new THREE.PerspectiveCamera( this.fieldOfView, this.width / this.height, 0.01, 1000 );
        this.camera.position.x = this.defCameraPosition.x;
        this.camera.position.y = this.defCameraPosition.y;
        this.camera.position.z = this.defCameraPosition.z;

        this.ambientLight  = new THREE.AmbientLight(ambientColor, ambientIntensity);
        this.scene.add( this.ambientLight );

        this.directionalLight = new THREE.DirectionalLight(directionalColor, directionalIntensity);
        this.directionalLight.position.set(directionalLightX, directionalLightY, directionalLightZ).normalize();
        this.scene.add(this.directionalLight);


        //Controls
        if(this.enabledOrbitControls){
            this.controls = new OrbitControls(this.camera, this.renderer.domElement)
            // this.controls.enableDamping = true
        }
    }

    load(){
        this.container = new THREE.Object3D();
        this.scene.add(this.container);

        this.loadTexture(this.ENV_IMG_PATH, "texture_env")
            .then(this.loadTexture.bind(this, this.POINT_IMG_PATH, "texture_point"))
            .then(this.loadTexture.bind(this, this.BAK_BUILDING_IMG_PATH, "texture_bake_building"))
            .then(this.loadTexture.bind(this, this.BAK_GROUND_IMG_PATH, "texture_bake_ground"))
            .then(this.loadTexture.bind(this, this.BAK_ROAD_IMG_PATH, "texture_bake_road"))
            .then(this.loadTexture.bind(this, this.BAK_SHADOW_IMG_PATH, "texture_bake_shadow"))
            .then(this.loadGeom.bind(this))
            .then(this.loadCar.bind(this))
            .then(this.loadCarWire.bind(this))
            .then(this.loadSensor.bind(this))
            .then(this.load6Layers04.bind(this))
            .then(this.load6Layers06.bind(this))
            .then(this.loadDefineUseCase.bind(this))
            .then(this.loadReferenceDesignInterior.bind(this))
            .then(this.loadReferenceDesignRoof.bind(this))
            .then(this.loadReferenceDesignSensor.bind(this))
            // .then(this.loadMainRout.bind(this))
            // .then(this.loadDashedRout.bind(this))
            .then(() => {
                this.initMesh();
                let roadColor = this.road.material.color;
                let groundColor = this.ground.material.color;
                this.defRoadColor = {r:roadColor.r,g:roadColor.g,b:roadColor.b};
                this.defGroundColor = {r:groundColor.r,g:groundColor.g,b:groundColor.b};

                TweenMax.delayedCall(.5, ()=>{
                    this.loaded = true;
                    trace("HERO 3D loaded");
                });
            });
    }


    loadTexture(path, name){
        let textureLoader = new THREE.TextureLoader();
        return new Promise((resolve) =>{
            textureLoader.load(path, (texture) => {
                texture.mapping = THREE.UVMapping;
                texture.flipY = false;
                texture.minFilter = LinearFilter;
                texture.magFilter = LinearFilter;
                this[name] = texture;

                return resolve();
            });
        });

    }

    loadGeom(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/geom_meshopt.glb',
                (gltf) => {
                    const scene = gltf.scene || gltf.scenes[0];
                    const clips = gltf.animations || [];


                    // trace("geom.glb", gltf);
                    this.setGeom(scene, clips);

                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    showGeom(geom, opacity){
        const material = geom.material;
        // material.opacity = opacity || 1;
        material.needsUpdate = true;

        let o = opacity || 1;

        TweenMax.killTweensOf(material);
        TweenMax.to(material, 1, {opacity:o, ease:Quart.easeOut});
    }

    hideGeom(geom){
        const material = geom.material;
        material.transparent = true;
        material.alphaToCoverage = true;
        // material.opacity = 0;
        material.needsUpdate = true;
        TweenMax.killTweensOf(material);
        TweenMax.to(material, 1, {opacity:0, ease:Quart.easeOut});
    }

    setGeom(object, clips){
        this.container.add(object);
        this.content = object;

        this.content.traverse((node) => {
            if(node.name === "GRP_car") {
                this.car = node;
                this.carTyres = [];
                node.traverse((child) => {
                    if(child.isMesh) {
                        child.material.transparent = true;
                        child.material.alphaToCoverage = true;
                        child.material.needsUpdate = true;
                    }
                    if(child.name.indexOf("GEOM_Tyre") > -1) this.carTyres.push(child);
                });

                this.carCameraTarget = new THREE.Object3D();
                this.car.add(this.carCameraTarget);

                this.cameraLookAtTarget = new THREE.Object3D();
                this.car.add(this.cameraLookAtTarget);
            }else if(node.name === "GEOM_Building") {
                this.building = node;
                node.material.lightMap = this.texture_bake_building;
                node.material.needsUpdate = true;
                node.position.y -= 1;
            }else if(node.name === "GEOM_Ground") {
                this.ground = node;
                node.material.color = new THREE.Color(this.SOLID_GROUND_COLOR);
                node.material.lightMap = this.texture_bake_ground;
                node.material.needsUpdate = true;
                node.position.y -= 1;
            }else if(node.name === "GEOM_Road") {
                this.road = node;
                node.material.color = new THREE.Color(this.SOLID_ROAD_COLOR);
                node.material.lightMap = this.texture_bake_road;
                node.material.needsUpdate = true;
            }else if(node.name === "GEOM_BoxCar") {
                this.carWire = node;
                this.hideGeom(node);

                for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
                    let clone = this.carWire.clone();
                    this.container.add(clone);
                    this.hideGeom(clone);
                    this.carWireClones.push(clone);
                }
            }else if(node.name === "GEOM_CarRoof") {
                this.carRoof = node;
                this.carRoofDefY = node.position.y;
            }else if(node.name === "GEOM_CarInterior") {
                this.carInterior = node;
                this.carInteriorDefY = node.position.y;
            }else if(node.name === "GEOM_CarSensor") {
                this.carSensor = node;
                this.carSensorDefY = node.position.y;
            }else if(node.name === "GEOM_BoxHuman") {
                this.human = node;
                this.hideGeom(node);
                this.human.position.x = -164.7;
                this.human.position.z = -3;
                trace("human", this.human);

                let rotationYList = [
                    this.pack.d2r(160),
                    this.pack.d2r(-210),
                    this.pack.d2r(-190),
                    this.pack.d2r(-130),
                    this.pack.d2r(-140),
                    this.pack.d2r(70)
                ];

                let positionList = [
                    {x:-170, z:6.5},
                    {x:-165.5, z:7.0},
                    {x:-166.5, z:6.5},
                    {x:-163.0, z:-5},
                    {x:-164.5, z:-4.5},
                    {x:-165.5, z:-4}
                ];

                for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
                    let clone = this.human.clone();
                    this.container.add(clone);
                    this.humanClones.push(clone);
                    clone.position.x = positionList[i].x;
                    clone.position.z = positionList[i].z;
                    clone.rotation.y = rotationYList[i];
                    this.hideGeom(clone);
                    // trace("human", clone);
                }

                let newMaterial = new THREE.MeshStandardMaterial();
                newMaterial.color.r = 0;
                newMaterial.color.g = 0;
                newMaterial.color.b = 0;
                newMaterial.emissive.r = 0;
                newMaterial.emissive.g = .5;
                newMaterial.emissive.b = 1;
                this.human.material = newMaterial;
                this.human.material.needsUpdate = true;
            }else if(node.name === "GEOM_TrafficLight") {
                this.trafficLight = node;
                this.trafficLight.position.x = -175;
                this.trafficLight.position.z = -9;
                this.trafficLight.rotation.y = this.pack.d2r(-20);
                this.hideGeom(node);

                let rotationYList = [
                    this.pack.d2r(160),
                    this.pack.d2r(-110),
                    this.pack.d2r(70)
                ];

                let positionList = [
                    {x:-170, z:6.5},
                    {x:-165.5, z:-3},
                    {x:-181.5, z:1}
                ];

                for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
                    let clone = this.trafficLight.clone();
                    this.container.add(clone);
                    this.hideGeom(clone);
                    this.trafficLightClones.push(clone);
                    clone.position.x = positionList[i].x;
                    clone.position.z = positionList[i].z;
                    clone.rotation.y = rotationYList[i];
                }

            }else if(node.name === "GEOM_TrafficSign") {
                this.trafficSign = node;
                this.trafficSign.position.x = -165;
                this.trafficSign.position.z = -8;
                this.trafficSign.rotation.y = this.pack.d2r(-20);
                this.hideGeom(node);
            }else if(node.name === "GEOM_Corn") {
                this.trafficCorn = node;
                this.trafficCorn.position.x = -166;
                this.trafficCorn.position.z = -20;
                this.hideGeom(node);

                for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
                    let clone = this.trafficCorn.clone();
                    this.hideGeom(clone);
                    this.container.add(clone);
                    this.cornClones.push(clone);
                    clone.position.x = -166 - (i + 1) * .8;
                    clone.position.z = -20 + (i + 1) * 2;
                }
            }


           /* if(node.name === "GEOM_Body") {
                this.car = node;
                this.carTyres = [];
                node.traverse((child) => {
                    if(child.isMesh) child.material.transparent = true;
                    if(child.name.indexOf("GEOM_Tyre") > -1) this.carTyres.push(child);
                });

                this.carCameraTarget = new THREE.Object3D();
                this.car.add(this.carCameraTarget);

                this.cameraLookAtTarget = new THREE.Object3D();
                this.car.add(this.cameraLookAtTarget);

            }else if(node.name === "GEOM_CarWire") {
                this.carWire = node;

                for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
                    let clone = this.carWire.clone();
                    this.container.add(clone);
                    this.carWireClones.push(clone);
                }

            }else if(node.name === "GEOM_Building") {
                this.building = node;
                node.material.lightMap = this.texture_bake_building;
                node.material.needsUpdate = true;
                node.position.y -= 1;
                // let mat = new THREE.MeshBasicMaterial({lightMap: this.texture_bake_building });
                // node.material = mat;
                // node.material.needsUpdate = true;
                // this.hideGeom(node);
            }else if(node.name === "GEOM_PointCloud") {
                this.hideGeom(node);
                this.setPointCloud(node);
                this.container.add(this.pointCloud);
                node.position.y -= 1;
                this.hideGeom(this.pointCloud);
            }else if(node.name === "GEOM_Ground") {
                this.ground = node;
                node.material.color = new THREE.Color(this.SOLID_GROUND_COLOR);
                node.material.lightMap = this.texture_bake_ground;
                node.material.needsUpdate = true;
                // this.hideGeom(node);
                node.position.y -= 1;

            }else if(node.name === "GEOM_Road") {
                this.road = node;
                node.material.color = new THREE.Color(this.SOLID_ROAD_COLOR);
                node.material.lightMap = this.texture_bake_road;
                node.material.needsUpdate = true;
                // this.hideGeom(node);
            }else if(node.name === "GEOM_Human") {
                this.human = node;
                this.human.position.x = 175;
                this.human.position.z = -130;
                this.human.rotation.y = this.pack.d2r(180);
            }else if(node.name === "GEOM_Shadow") {
                this.carShadow = node;
                node.material.transparent = true;
                node.material.alphaToCoverage = true;
                node.material.map = this.texture_bake_shadow;
                node.material.needsUpdate = true;
                node.material.opacity = 1;
            }*/
        });
    }

    loadCar(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/anim_maincarloop_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "car");
                    gltf.scene.traverse((child) => {
                        if (child instanceof THREE.Mesh === true) {
                            this.cameraTarget = child;
                        }
                    });
                    // this.container.add(gltf.scene);
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadCarWire(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/anim_otherloop_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "carWire");
                    gltf.scene.traverse((child) => {
                        if(child.name === "followpath_filtered_otherroute"){
                            this.carWireTarget = child;
                        }
                    });

                    for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
                        let sceneClone = gltf.scene.clone();
                        let id = i + 1;
                        this.setAnimationClip(sceneClone, gltf.animations, "carWireClone" + id, false, (12 + Math.random() * .5) * id);
                        sceneClone.traverse((child) => {
                            if(child.name === "followpath_filtered_otherroute"){
                                this["carWireTarget" + id] = child;
                            }
                        });
                    }

                    // this.container.add(gltf.scene);
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadSensor(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/anim_sensorflat_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "sensor");
                    this.sensorMeshMaterials = [];
                    gltf.scene.traverse((child) => {
                        if (child instanceof THREE.Mesh === true) {
                            const material = child.material;
                            material.transparent = true;
                            material.alphaToCoverage = true;
                            // material.opacity = 0.7;
                            material.needsUpdate = true;
                            this.sensorMeshMaterials.push(material);
                        }
                    });

                    this.sensor = gltf.scene;
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    load6Layers04(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/anim_sixlayer04_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "sixLayers04");
                    // this.container.add(gltf.scene);
                    this.sixLayers04 = gltf.scene;

                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    load6Layers06(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/anim_sixlayer06_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "sixLayers06");
                    // this.container.add(gltf.scene);
                    this.sixLayers06 = gltf.scene;
                    this.sixLayers06.position.x = -.8;      //位置微調整

                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadDefineUseCase(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/anim_defineusecase_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "defineUseCase", true);
                    // this.container.add(gltf.scene);
                    this.defineUseCase = gltf.scene;

                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadReferenceDesignInterior(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/anim_referencedesign_interior_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "referenceDesignInterior", true);
                    // this.container.add(gltf.scene);
                    this.referenceDesignInterior = gltf.scene;
                    this.referenceDesignInteriorTarget = gltf.scene.getObjectByName("__offset03");
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadReferenceDesignRoof(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/anim_referencedesign_roof_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "referenceDesignRoof", true);
                    // this.container.add(gltf.scene);
                    this.referenceDesignRoof = gltf.scene;
                    this.referenceDesignRoofTarget = gltf.scene.getObjectByName("__offset02");


                    trace(gltf.scene);

                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadReferenceDesignSensor(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/anim_referencedesign_sensor_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "referenceDesignSensor", true);
                    // this.container.add(gltf.scene);
                    this.referenceDesignSensor = gltf.scene;
                    this.referenceDesignSensorTarget = gltf.scene.getObjectByName("__offset01");
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadMainRout(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/s05_mainroute_anim_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "mainRout", true);

                    // gltf.scene.position.y = .2;
                    this.container.add(gltf.scene);
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadDashedRout(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/s05_dashroute_anim_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "dashedRout", true);

                    // gltf.scene.position.y = .2;
                    this.container.add(gltf.scene);
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    setAnimationClip(scene, animations, name, isOnce, offsetTime){
        let mixer = new THREE.AnimationMixer( scene );
        let anime = {};
        anime.mixer = mixer;
        anime.name = name;

        animations.forEach((clip, clipIndex) => {
            let action = mixer.clipAction(clip);
            if(isOnce) {
                //1回のみ再生
                action.setLoop(THREE.LoopOnce);
                action.clampWhenFinished = true;
            }

            anime.duration = clip.duration;
            anime.action = action;
        });

        if(!offsetTime) offsetTime = 0;
        anime.offsetTime = offsetTime;  //開始点指定
        this.animeList.push(anime);
    }

    initMesh(){
        // for debug
        // let geometry = new THREE.PlaneGeometry( 3, 3, 4 );
        // var material = new THREE.MeshBasicMaterial( {color: 0xff0000, opacity:.5, side: THREE.DoubleSide} );
        // this.plane = new THREE.Mesh( geometry, material );
        // this.scene.add( this.plane );

        let options = {
            generateMipmaps: true,
            minFilter: THREE.LinearMipmapLinearFilter,
            magFilter: THREE.LinearFilter
        };

        //for Environment
        const renderTargetCube = new THREE.WebGLCubeRenderTarget( 1024, options ).fromEquirectangularTexture( this.renderer, this.texture_env );

        //add EnvironmentMap
        this.updateEnvironment(renderTargetCube.texture);
    }

    start(){
        window.scrollTo(0, 0);
        this.initPosVectorForScene3();
        this.startScene1();

        let delay = 2.5;
        TweenMax.delayedCall(delay, () => {
            this.dispatchEvent("openingEnded");
        });
    }

    initPosVectorForScene3(){
        this.cameraLookAtTargetPosVectorForScene3 = new THREE.Vector3(68.16640483005366, 2.1, -114.95982765389802 );
        this.cameraTargetPosVectorForScene3 = new THREE.Vector3(69.49970627529714, 2.999999999999998, -105.02442090550005 );
    }

    transitPosVectorForScene3(){
        if(this.sw >= this.pack.BP){
            TweenMax.to(this.cameraLookAtTargetPosVectorForScene3, .5, {x:177.9500803250232, y:2.1000000000000014, z:-140.44301588229436, ease:Quart.easeInOut});
        }else{
            TweenMax.to(this.cameraLookAtTargetPosVectorForScene3, .5, {x:188, y:7.1, z:-140, ease:Quart.easeInOut});
        }

        TweenMax.to(this.cameraTargetPosVectorForScene3, .5, {x:200, y:9.5, z:-148, ease:Quart.easeInOut});
    }

    setScene(id, isPrev){
        let delay;
        this.currentScene = id;

        if(this.currentScene === 1){
            this.startScene1(isPrev);
            delay = 2.0;
        }else if(this.currentScene === 2){
            this.startScene2(isPrev);
            delay = 2.0;
        }else if(this.currentScene === 3){
            this.startScene3(isPrev);
            delay = 2.8;
        }else if(this.currentScene === 4){
            this.startScene4(isPrev);
            delay = 2.3;
        }else if(this.currentScene === 5){
            this.startScene5(isPrev);
            delay = 2;
        }else if(this.currentScene === 6){
            this.startScene6(isPrev);
            delay = 2;
        }else if(this.currentScene === 7){
            this.startScene7(isPrev);
            delay = 2;
        }else if(this.currentScene === 8){
            this.startScene8(isPrev);
            delay = 2.5;
        }else if(this.currentScene === 9){
            this.startScene9(isPrev);
            delay = 2;
        }

        TweenMax.delayedCall(delay, () => {
            this.dispatchEvent("changedScene");
        });
    }

    skipScene(){
        TweenMax.delayedCall(.5, ()=>{
            this.transitPosVectorForScene3();
            this.container.remove(this.sensor);
            this.showGeom(this.ground);
            this.showGeom(this.building);
            this.startScene8();
        });
    }

    startScene1(isPrev){

        TweenMax.to(this.directionalLight, 1, {intensity:directionalIntensity, ease:Quad.easeOut});

        if(this.sw >= this.pack.BP){

            //Scene1
            /*
            cameraTargetX = 7.5, cameraTargetY = 2.7, cameraTargetZ = -18.6, cameraLookAtTargetX = -8.7, cameraLookAtTargetY = 1, cameraLookAtTargetZ = 4.8,
            this.carCameraTarget.position.x = 7.5, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -18.6, this.cameraLookAtTarget.position.x = -8.7, this.cameraLookAtTarget.position.y = 1, this.cameraLookAtTarget.position.z = 4.8;
             */

            this.carCameraTarget.position.x = 7.5, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -18.6, this.cameraLookAtTarget.position.x = -8.7, this.cameraLookAtTarget.position.y = 1, this.cameraLookAtTarget.position.z = 4.8;

            //for layout
/*            this.carCameraTarget.position.x = cameraTargetX;
            this.carCameraTarget.position.y = cameraTargetY;
            this.carCameraTarget.position.z = cameraTargetZ;
            this.cameraLookAtTarget.position.x = cameraLookAtTargetX;
            this.cameraLookAtTarget.position.y = cameraLookAtTargetY;
            this.cameraLookAtTarget.position.z = cameraLookAtTargetZ;*/

/*

            //for debug
            this.carCameraTarget.position.x = 10;
            this.carCameraTarget.position.y = 45;
            // this.carCameraTarget.position.y = 350;
            this.carCameraTarget.position.z = 10;
            this.cameraLookAtTarget.position.x = 0;
            this.cameraLookAtTarget.position.y = 0;
            this.cameraLookAtTarget.position.z = 0;*/

        }else{
            //Scene1
            /*
            cameraTargetX = 5.7, cameraTargetY = 2.7, cameraTargetZ = -15.3, cameraLookAtTargetX = -7.5, cameraLookAtTargetY = 3, cameraLookAtTargetZ = 4.8,
            this.carCameraTarget.position.x = 5.7, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -15.3, this.cameraLookAtTarget.position.x = -7.5, this.cameraLookAtTarget.position.y = 3, this.cameraLookAtTarget.position.z = 4.8;
             */
            this.carCameraTarget.position.x = 5.7, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -15.3, this.cameraLookAtTarget.position.x = -7.5, this.cameraLookAtTarget.position.y = 3, this.cameraLookAtTarget.position.z = 4.8;
        }

        //for layout
        // let cloneCnt = 0;

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "car") {
                action.paused = false;
                action.play();
                // action.reset().play();
                // mixer.setTime(0);
            }

/*
            if(anime.name === "car" || anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0) {
                action.paused = false;
                action.reset().play();
                mixer.setTime(anime.offsetTime);
            }
*/

            /*

                        //for layout start
                        if(anime.name === "car"){
                            action.paused = false;
                            action.reset().play();
                            mixer.setTime(47.2);
                            action.paused = true;
                        }

                        if(anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0){
                            if(cloneCnt < 3){
                                action.paused = false;
                                action.reset().play();
                                let time = 77.5 + cloneCnt * 2.25;
                                mixer.setTime(time);
                                action.paused = true;
                            }else{
                                action.paused = false;
                                action.reset().play();
                                mixer.setTime(0);
                                action.paused = true;
                            }
                            cloneCnt++;
                        }
                        //for layout end

            */
/*
            //for debug
            if(anime.name === "sensor" || anime.name === "sixLayers04" || anime.name === "sixLayers06" || anime.name === "defineUseCase" || anime.name === "referenceDesignInterior" || anime.name === "referenceDesignRoof" || anime.name === "referenceDesignSensor"){
                action.paused = false;
                action.reset().play();
                mixer.setTime(0);
            }*/
        }

/*        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.showGeom(item);
        }
        this.showGeom(this.carWire);*/

/*
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.hideGeom(item);
        }
        this.hideGeom(this.carWire);
*/



        // this.container.add(this.sensor);
        this.showGeom(this.building);
        this.showGeom(this.road);
        this.showGeom(this.ground);
    }

    startScene2(isPrev){
        // this.cameraLookAtTarget.position.x = this.currentCarPosVector.x - 10;
        // this.cameraLookAtTarget.position.y = this.currentCarPosVector.y;
        // this.cameraLookAtTarget.position.z = this.currentCarPosVector.z - 10;
        // TweenMax.to(this.directionalLight, 1, {intensity:1, ease:Quad.easeOut});


        if(this.sw >= this.pack.BP){
            //Scene2
            /*
            cameraTargetX = 11.4, cameraTargetY = 1.2, cameraTargetZ = 7.2, cameraLookAtTargetX = 3.3, cameraLookAtTargetY = 6, cameraLookAtTargetZ = -14.4,
            this.carCameraTarget.position.x = 11.4, this.carCameraTarget.position.y = 1.2, this.carCameraTarget.position.z = 7.2, this.cameraLookAtTarget.position.x = 3.3, this.cameraLookAtTarget.position.y = 6, this.cameraLookAtTarget.position.z = -14.4;
             */
            this.carCameraTarget.position.x = 11.4, this.carCameraTarget.position.y = 1.2, this.carCameraTarget.position.z = 7.2, this.cameraLookAtTarget.position.x = 3.3, this.cameraLookAtTarget.position.y = 6, this.cameraLookAtTarget.position.z = -14.4;
        }else{
            /*
             cameraTargetX = 11.4, cameraTargetY = 1.2, cameraTargetZ = 13.2, cameraLookAtTargetX = -8.7, cameraLookAtTargetY = 15, cameraLookAtTargetZ = -29.4,
             this.carCameraTarget.position.x = 11.4, this.carCameraTarget.position.y = 1.2, this.carCameraTarget.position.z = 13.2, this.cameraLookAtTarget.position.x = -8.7, this.cameraLookAtTarget.position.y = 15, this.cameraLookAtTarget.position.z = -29.4;
              */
            this.carCameraTarget.position.x = 11.4, this.carCameraTarget.position.y = 1.2, this.carCameraTarget.position.z = 13.2, this.cameraLookAtTarget.position.x = -8.7, this.cameraLookAtTarget.position.y = 15, this.cameraLookAtTarget.position.z = -29.4;
        }





        this.container.remove(this.sensor);
        this.hideGeom(this.carWire);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.hideGeom(item);
        }


        //for debug
/*
        this.carCameraTarget.position.x = -2.5;
        this.carCameraTarget.position.y = 2;
        this.carCameraTarget.position.z = -12.5;
*/

/*
        if(this.sw >= this.pack.BP){
            TweenMax.to(this.cameraLookAtTarget.position, .9, {x:1, y:2.6, z:-3.3, ease:Quart.easeOut});
        }else{
            TweenMax.to(this.cameraLookAtTarget.position, .9, {x:0, y:2.6, z:-1.3, ease:Quart.easeOut});
        }
*/

/*        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;
            action.paused = true;
            if(anime.name === "car"){
                action.paused = false;
                mixer.setTime(14);
                action.play();
                action.paused = true;
            }else if(anime.name === "wind"){
                action.paused = false;
                action.reset().play();
            }
        }*/


/*
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.hideGeom(item);
        }
        this.hideGeom(this.carWire);
        this.hideGeom(this.building);
        this.hideGeom(this.ground);
        this.container.remove(this.

        );
        this.container.add(this.wind);
*/

        TweenMax.killTweensOf(this);
        this.currentWheelSpeed = this.wheelSpeed;
    }

    startScene3(isPrev){

        // TweenMax.to(this.directionalLight, 1, {intensity:directionalIntensity, ease:Quad.easeOut});

        if(!isPrev){
            //TODO 動的に値を代入できるようにしたい
            /*
                scene2のthis.cameraLookAtTargetのワールド座標(以下で求めた値)
                let cameraLookAtTargetPosVector = new THREE.Vector3();
                cameraLookAtTargetPosVector = this.cameraLookAtTarget.getWorldPosition(cameraLookAtTargetPosVector);
                x: 68.16640483005366
                y: 2.1
                z: -114.95982765389802

                scene3のthis.cameraLookAtTargetのワールド座標(以下で求めた値)
                let cameraLookAtTargetPosVector = new THREE.Vector3();
                cameraLookAtTargetPosVector = this.cameraLookAtTarget.getWorldPosition(cameraLookAtTargetPosVector);
                x: 66.95807593049197
                y: 2.1000000000000014
                z: -120.32638532809514

                x: 177.9500803250232
                y: 2.1000000000000014
                z: -140.44301588229436

                scene2の車の位置
                x: 69.49970627529714
                y: 2.999999999999998
                z: -105.02442090550005
             */

/*            this.initPosVectorForScene3();
            this.transitPosVectorForScene3();*/
        }


        // this.cameraLookAtTarget.position.x = 5.5;
        // this.cameraLookAtTarget.position.y = 2.1;
        // this.cameraLookAtTarget.position.z = -3.4;


        if(this.sw >= this.pack.BP){
            //Scene3
            this.carCameraTarget.position.x = -30.6, this.carCameraTarget.position.y = 150, this.carCameraTarget.position.z = 91.2, this.cameraLookAtTarget.position.x = -36, this.cameraLookAtTarget.position.y = -96, this.cameraLookAtTarget.position.z = -55.8;
        }else{
            //Scene3
            /*

             */
            this.carCameraTarget.position.x = -14.6, this.carCameraTarget.position.y = 100, this.carCameraTarget.position.z = 72, this.cameraLookAtTarget.position.x = -17.1, this.cameraLookAtTarget.position.y = -96, this.cameraLookAtTarget.position.z = -70.8;
        }


        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "car") {
                action.paused = false;
                action.play();
            }else if(anime.name === "sensor"){
                action.paused = false;
                action.reset().play();
                mixer.setTime(0);
            }
        }

        this.container.add(this.sensor);
        this.showGeom(this.road);
        this.showGeom(this.building);
        this.showGeom(this.ground);
        this.showGeom(this.carWire);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.showGeom(item);
        }


        this.hideGeom(this.trafficLight);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.trafficSign);

        this.hideGeom(this.trafficCorn);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.human);
        for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
            let item = this.humanClones[i];
            this.hideGeom(item);
        }

        let color = this.DEF_COLOR;
        let mesh = this.carWire;
        let emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);


        /*
                let r = 48/255;
                let g = 152/255;
                let b = 166/255;

                this.human.material.color.r = 0.666;
                this.human.material.color.g = 0.666;
                this.human.material.color.b = 0.666;
        */

        let delay;
        if(isPrev) delay = .1;
        else delay = .3;

        TweenMax.delayedCall(2.0, ()=>{
            //sensor
            for( let i = 0, len = this.animeList.length; i < len; i++ ){
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;

                if(anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(anime.offsetTime);
                }else if(anime.name === "sensor"){
                    mixer.time = 0;
                    action.paused = false;
                    action.reset().play();
                }
            }

            // this.container.add(this.sensor);


/*
            TweenMax.killTweensOf(this.human.material.color);
            TweenMax.to(this.human.material.color, 1.5, {delay:1, r:r,g:g,b:b, ease:Quad.easeOut});
*/
        });

        TweenMax.delayedCall(delay, ()=>{
            //car
            let dr = 2;
            let delay = .6;

            /*
                止まる位置
                x: 182.3904571533203, y: 0, z: -135.74269104003906
            */

/*            let carDefX = 218;
            let carDefY = 0;
            let carDefZ = -136.1;

            this.car.position.x = carDefX;
            this.car.position.y = carDefY;
            this.car.position.z = carDefZ;
            TweenMax.killTweensOf([this.car.position/!*, this.wind.position, this.carShadow.position, this.sensor.position*!/]);
            TweenMax.fromTo([this.car.position/!*, this.wind.position, this.sensor.position*!/], dr, {x:carDefX, y:carDefY, z:carDefZ}, {delay:delay, x:this.currentCarPosVector.x, y:this.currentCarPosVector.y, z:this.currentCarPosVector.z, ease:Quad.easeOut});
            // TweenMax.fromTo([this.carShadow.position], dr, {x:carDefX, y:this.carShadowY, z:carDefZ}, {delay:delay, x:this.currentCarPosVector.x, z:this.currentCarPosVector.z, ease:Quad.easeOut});

            //wheel
            TweenMax.killTweensOf(this);
            TweenMax.to(this, dr, {delay:delay, currentWheelSpeed:0, ease:Quad.easeOut, onUpdate:()=>{
                this.wheelsDeg += this.currentWheelSpeed;
                let wheelsRad = this.pack.d2r(this.wheelsDeg);
                for( let i = 0, len = this.carTyres.length; i < len; i++ ){
                    let mesh = this.carTyres[i];
                    if(i === 0 || i === 2) mesh.rotation.x = wheelsRad;
                    else mesh.rotation.x = -wheelsRad;
                }
            }});*/
        });


        // this.changeToDefColor();
    }

    startScene4(isPrev){

        // this.carCameraTarget.position.x = this.SCENE4_7_CAMERA_INFO.targetX;
        // this.carCameraTarget.position.y = this.SCENE4_7_CAMERA_INFO.targetY;
        // this.carCameraTarget.position.z = this.SCENE4_7_CAMERA_INFO.targetZ;

        // this.cameraLookAtTarget.position.x = this.SCENE4_7_CAMERA_INFO.lookAtTargetX;
        // this.cameraLookAtTarget.position.y = this.SCENE4_7_CAMERA_INFO.lookAtTargetY;
        // this.cameraLookAtTarget.position.z = this.SCENE4_7_CAMERA_INFO.lookAtTargetZ;

        //Scene4
        /*

         */
        this.carCameraTarget.position.x = 5.4, this.carCameraTarget.position.y = 3.3, this.carCameraTarget.position.z = 2.1, this.cameraLookAtTarget.position.x = -6.9, this.cameraLookAtTarget.position.y = 2.7, this.cameraLookAtTarget.position.z = -13.5;

        // this.hideGeom(this.building);
        // this.showGeom(this.ground);
        this.container.remove(this.sensor);
        this.showGeom(this.human);
        for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
            let item = this.humanClones[i];
            this.showGeom(item);
        }

        this.hideGeom(this.trafficLight);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.trafficSign);

        this.hideGeom(this.trafficCorn);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            this.hideGeom(item);
        }

        this.container.remove(this.defineUseCase);

        TweenMax.killTweensOf(this);
        this.currentWheelSpeed = this.wheelSpeed;

        let color = this.ORANGE_COLOR;
        let mesh = this.carWire;
        let emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color, 2);

        color = this.DEF_COLOR;
        mesh = this.human;
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        let cloneCnt = 0;
        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "car"){
                action.paused = false;
                action.reset().play();
                mixer.setTime(47.2);
                action.paused = true;
            }else if(anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0){
                if(cloneCnt < 3){
                    action.paused = false;
                    action.reset().play();
                    let time = 78.25 + cloneCnt * 2.25;
                    mixer.setTime(time);
                    action.paused = true;
                }else{
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                    action.paused = true;
                }
                cloneCnt++;
            }
        }
    }

    startScene5(isPrev){

        // this.carCameraTarget.position.y = this.SCENE4_7_CAMERA_INFO.targetY + 30;
        // this.carCameraTarget.position.z = this.SCENE4_7_CAMERA_INFO.targetZ + 30;

        if(this.sw >= this.pack.BP){
            //Scene5
            this.carCameraTarget.position.x = -11.1, this.carCameraTarget.position.y = 7.5, this.carCameraTarget.position.z = -25.8, this.cameraLookAtTarget.position.x = 8.7, this.cameraLookAtTarget.position.y = -6.9, this.cameraLookAtTarget.position.z = 0.3;
        }else{
            //Scene5
            /*
                cameraTargetX = -11.1, cameraTargetY = 7.5, cameraTargetZ = -25.8, cameraLookAtTargetX = 2.1, cameraLookAtTargetY = -6.9, cameraLookAtTargetZ = 0.3,
                this.carCameraTarget.position.x = -11.1, this.carCameraTarget.position.y = 7.5, this.carCameraTarget.position.z = -25.8, this.cameraLookAtTarget.position.x = 2.1, this.cameraLookAtTarget.position.y = -6.9, this.cameraLookAtTarget.position.z = 0.3;
             */
            //Scene5
            this.carCameraTarget.position.x = -11.1, this.carCameraTarget.position.y = 7.5, this.carCameraTarget.position.z = -25.8, this.cameraLookAtTarget.position.x = 2.1, this.cameraLookAtTarget.position.y = -6.9, this.cameraLookAtTarget.position.z = 0.3;
        }

        this.showGeom(this.trafficLight);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            this.showGeom(item);
        }

        this.showGeom(this.trafficSign);

        this.showGeom(this.trafficCorn);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            this.showGeom(item);
        }

        let color = this.DEF_COLOR;
        let mesh = this.carWire;
        let emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        color = this.ORANGE_COLOR;
        mesh = this.human;
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color, 1.4);

        this.resetSubScene6();
        this.pack.top.resetHeroContents(6);

        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "defineUseCase"){
                TweenMax.delayedCall(2, ()=>{
                    this.container.add(this.defineUseCase);
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                });
            }
        }


/*        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "car" || anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0) {
                if(isPrev){
                    action.paused = false;
                    mixer.setTime(anime.offsetTime);
                    action.reset().play();
                    action.paused = true;
                }
            }else if(anime.name === "mainRout"){
                if(!isPrev){
                    action.paused = false;
                    action.reset().play();
                }
            }else if(anime.name === "dashedRout"){
                action.paused = false;
                mixer.setTime(0);
                action.reset().play();
                action.paused = true;
            }
        }*/
    }

    startScene6(isPrev){

        // this.carCameraTarget.position.y = this.SCENE4_7_CAMERA_INFO.targetY + 60;
        // this.carCameraTarget.position.z = this.SCENE4_7_CAMERA_INFO.targetZ + 60;

        if(this.sw >= this.pack.BP){
            //Scene6
            this.carCameraTarget.position.x = -2.7, this.carCameraTarget.position.y = 4.2, this.carCameraTarget.position.z = 33.9, this.cameraLookAtTarget.position.x = -8.4, this.cameraLookAtTarget.position.y = 10.5, this.cameraLookAtTarget.position.z = -27.6;
        }else{
            //Scene6
            /*
             */
            //Scene5
            this.carCameraTarget.position.x = -2.7, this.carCameraTarget.position.y = 2.1, this.carCameraTarget.position.z = 26, this.cameraLookAtTarget.position.x = 1.8, this.cameraLookAtTarget.position.y = -3.9, this.cameraLookAtTarget.position.z = -27.6;
        }

        this.showGeom(this.trafficLight);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            this.showGeom(item);
        }

        this.showGeom(this.trafficSign);

        this.showGeom(this.trafficCorn);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            this.showGeom(item);
        }

        this.showGeom(this.human);
        for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
            let item = this.humanClones[i];
            this.showGeom(item);
        }

        this.showGeom(this.carWire);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.showGeom(item);
        }

        let color = this.DEF_COLOR;
        let mesh = this.human;
        let emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        let cloneCnt = 0;
        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0){
                if(cloneCnt < 3){
                    action.paused = false;
                    action.reset().play();
                    let time = 77.5 + cloneCnt * 2.25;
                    mixer.setTime(time);
                    action.paused = true;
                }else{
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                    action.paused = true;
                }
                cloneCnt++;
            }
        }

        this.resetSubScene7();
        this.pack.top.resetHeroContents(7);

        this.container.remove(this.defineUseCase);

        /*
                for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
                    let item = this.carWireClones[i];
                    this.showGeom(item);
                }
                this.showGeom(this.carWire);

                this.hideGeom(this.building);
        */
        // this.changeToDarkColor();

        /*
        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "car" || anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0) {
                if(!isPrev){
                    action.paused = false;
                    mixer.setTime(anime.offsetTime);
                    action.play();
                }
            }else if(anime.name === "dashedRout"){
                if(!isPrev){
                    action.paused = false;
                    action.reset().play();
                }
            }
        }
        */
    }

    startSubScene6(subScene){
        if(subScene === 1){
            let color = this.DEF_COLOR;
            this.tweenMaterialEmissive(this.trafficSign.material.emissive, color);
            this.tweenMaterialEmissive(this.trafficLight.material.emissive, color);
            for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
                let item = this.trafficLightClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }
        }else if(subScene === 2){
            let color = this.DEF_COLOR;
            this.tweenMaterialEmissive(this.trafficCorn.material.emissive, color);

            for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
                let item = this.cornClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }

            color = this.ORANGE_COLOR;
            this.tweenMaterialEmissive(this.trafficSign.material.emissive, color);
            this.tweenMaterialEmissive(this.trafficLight.material.emissive, color);
            for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
                let item = this.trafficLightClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }
        }else if(subScene === 3){
            let color = this.DEF_COLOR;
            this.tweenMaterialEmissive(this.trafficSign.material.emissive, color);
            this.tweenMaterialEmissive(this.trafficLight.material.emissive, color);
            for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
                let item = this.trafficLightClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }

            color = this.ORANGE_COLOR;
            this.tweenMaterialEmissive(this.trafficCorn.material.emissive, color);

            for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
                let item = this.cornClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }
            this.container.remove(this.sixLayers04);
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;

                if(anime.name === "sixLayers04"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                    action.paused = true;
                }
            }

        }else if(subScene === 4){
            TweenMax.to(this.ambientLight, 1, {intensity:ambientIntensity, ease:Quart.easeOut});

            let color = this.DEF_COLOR;
            this.tweenMaterialEmissive(this.trafficCorn.material.emissive, color);

            for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
                let item = this.cornClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }

            this.container.add(this.sixLayers04);
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;
                if(anime.name === "sixLayers04"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                }
            }

        }else if(subScene === 5){
            this.container.remove(this.sixLayers04);
            this.container.remove(this.sixLayers06);
            TweenMax.to(this.ambientLight, 1, {intensity:1, ease:Quad.easeOut});
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;
                if(anime.name === "sixLayers04"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                    action.paused = true;
                }else if(anime.name === "sixLayers06"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                    action.paused = true;
                }
            }
        }else if(subScene === 6){
            this.container.add(this.sixLayers06);
            TweenMax.to(this.ambientLight, 1, {intensity:ambientIntensity, ease:Quart.easeOut});
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;
                if(anime.name === "sixLayers06"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                }
            }
        }
    }

    resetSubScene6(){
        let color = this.DEF_COLOR;
        this.tweenMaterialEmissive(this.trafficSign.material.emissive, color);
        this.tweenMaterialEmissive(this.trafficLight.material.emissive, color);

        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            let emissive = item.material.emissive;
            this.tweenMaterialEmissive(emissive, color);
        }

        this.tweenMaterialEmissive(this.trafficCorn.material.emissive, color);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            let emissive = item.material.emissive;
            this.tweenMaterialEmissive(emissive, color);
        }

        this.container.remove(this.sixLayers04);
        this.container.remove(this.sixLayers06);
        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "sixLayers04"){
                action.paused = false;
                action.reset().play();
                mixer.setTime(0);
                action.paused = true;
            }else if(anime.name === "sixLayers06"){
                action.paused = false;
                action.reset().play();
                mixer.setTime(0);
                action.paused = true;
            }
        }

    }

    startScene7(isPrev){
        this.enabledUpdate = true;
        TweenMax.to(this.canvas, 1, {opacity:1, ease:Quad.easeOut});

        // this.carCameraTarget.position.x = this.SCENE4_7_CAMERA_INFO.targetX;
        // this.carCameraTarget.position.y = this.SCENE4_7_CAMERA_INFO.targetY + 60;
        // this.carCameraTarget.position.z = this.SCENE4_7_CAMERA_INFO.targetZ + 60;
        // this.cameraLookAtTarget.position.x = this.SCENE4_7_CAMERA_INFO.lookAtTargetX;
        // this.cameraLookAtTarget.position.y = this.SCENE4_7_CAMERA_INFO.lookAtTargetY;
        // this.cameraLookAtTarget.position.z = this.SCENE4_7_CAMERA_INFO.lookAtTargetZ;

        this.resetSubScene6();
        this.pack.top.resetHeroContents(6);

        if(this.sw >= this.pack.BP){
            //Scene7
            this.carCameraTarget.position.x = 3.3, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -5.4, this.cameraLookAtTarget.position.x = -3, this.cameraLookAtTarget.position.y = -0.6, this.cameraLookAtTarget.position.z = 2.7;
        }else{
            //Scene7
            /*
            cameraTargetX = 4.6, cameraTargetY = 2.7, cameraTargetZ = -9, cameraLookAtTargetX = -2.4, cameraLookAtTargetY = -0.6, cameraLookAtTargetZ = 4.5,
            this.carCameraTarget.position.x = 4.6, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -9, this.cameraLookAtTarget.position.x = -2.4, this.cameraLookAtTarget.position.y = -0.6, this.cameraLookAtTarget.position.z = 4.5;
             */
            this.carCameraTarget.position.x = 4.6, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -9, this.cameraLookAtTarget.position.x = -2.4, this.cameraLookAtTarget.position.y = -0.6, this.cameraLookAtTarget.position.z = 4.5;
        }

        this.hideGeom(this.carWire);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.trafficLight);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.trafficSign);

        this.hideGeom(this.trafficCorn);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.human);
        for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
            let item = this.humanClones[i];
            this.hideGeom(item);
        }

        /*        this.showGeom(this.ground);
                this.showGeom(this.building);*/
        // this.showGeom(this.human);

        // this.changeToDefColor();

/*        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.showGeom(item);
        }
        this.showGeom(this.carWire);*/

/*        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "mainRout" || anime.name === "dashedRout"){
                action.paused = false;
                mixer.setTime(anime.duration);
                action.play();
                action.paused = true;
            }else if(anime.name === "sensor07"){
                mixer.time = 0;
                action.paused = false;
                action.reset().play();
            }
        }*/
    }

    startSubScene7(subScene){
        if(subScene === 1){

        }else if(subScene === 2){
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;

                if(anime.name === "referenceDesignSensor"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                }else if (anime.name === "referenceDesignRoof" || anime.name === "referenceDesignInterior") {
                    action.paused = true;
                    mixer.setTime(0);
                }
            }
        }else if(subScene === 3){
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;

                if (anime.name === "referenceDesignRoof" || anime.name === "referenceDesignInterior") {
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                }
            }
        }
    }

    resetSubScene7(){
        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "referenceDesignSensor" || anime.name === "referenceDesignRoof" || anime.name === "referenceDesignInterior"){
                action.paused = false;
                action.reset().play();
                mixer.setTime(0);
                action.paused = true;
            }
        }
    }

    startScene8(isPrev){
        this.enabledUpdate = false;
        TweenMax.to(this.canvas, .5, {opacity:0, ease:Quad.easeOut});
        this.resetSubScene7();
        this.pack.top.resetHeroContents(7);

        this.carCameraTarget.position.x = -6;
        this.carCameraTarget.position.z = -20;

        if(this.sw >= this.pack.BP){
            this.carCameraTarget.position.y = 4.5;
            this.cameraLookAtTarget.position.x = -5.0;
            this.cameraLookAtTarget.position.y = -0.2;
            this.cameraLookAtTarget.position.z = -7.0;

        }else{
            this.carCameraTarget.position.y = 1.5;
            this.cameraLookAtTarget.position.x = 0;
            this.cameraLookAtTarget.position.y = -0.7;
            this.cameraLookAtTarget.position.z = -9.0;
        }

        this.showGeom(this.ground);
        // this.hideGeom(this.human);

/*        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "car" || anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0) {
                if(isPrev){
                    action.paused = false;
                    mixer.setTime(anime.offsetTime);
                    action.play();
                }
            }else if(anime.name === "mainRout" || anime.name === "dashedRout"){
                action.paused = false;
                mixer.setTime(0);
                action.play();
                action.paused = true;
            }
        }*/
    }

/*    startScene9(isPrev){
        this.enabledUpdate = false;
        TweenMax.to(this.canvas, .5, {opacity:0, ease:Quad.easeOut});
    }*/

    changeSubScene(scene, subScene){
        if(scene === 6) this.startSubScene6(subScene);
        else if(scene === 7) this.startSubScene7(subScene);
    }

    changeToDefColor(){
        let dr = 1;
        let ease = Quad.easeOut;

        TweenMax.killTweensOf(this.ground.material.color);
        TweenMax.killTweensOf(this.road.material.color);
        TweenMax.to(this.ground.material.color, dr, {r:this.defGroundColor.r, g:this.defGroundColor.g, b:this.defGroundColor.b, ease:ease});
        TweenMax.to(this.road.material.color, dr, {r:this.defRoadColor.r, g:this.defRoadColor.g, b:this.defRoadColor.b, ease:ease});
        TweenMax.to(this.ambientLight, dr, {intensity:ambientIntensity, ease:ease});

        this.car.traverse((child) => {
            if(child.isMesh) {
                child.material.blending = THREE.NormalBlending;
            }
        });
    }

    changeToDarkColor(){
        let dr = 1;
        let ease = Quad.easeOut;

        TweenMax.killTweensOf(this.ground.material.color);
        TweenMax.killTweensOf(this.road.material.color);

        let groundRGB = this.pack.hex2NormalRGB(this.POINTS_GROUND_COLOR);
        let roadRGB = this.pack.hex2NormalRGB(this.POINTS_ROAD_COLOR);


        TweenMax.to(this.ground.material.color, dr, {r:groundRGB.r, g:groundRGB.g, b:groundRGB.b, ease:ease});
        TweenMax.to(this.road.material.color, dr, {r:roadRGB.r, g:roadRGB.g, b:roadRGB.b, ease:ease});
        TweenMax.to(this.ambientLight, dr, {intensity:0, ease:ease});

        this.car.traverse((child) => {
            if(child.isMesh) {
                child.material.blending = THREE.AdditiveBlending;
            }
        });
    }

    scrollHandler(){

    }

    render(){
        this.renderer.render(this.scene, this.camera);
    }

    enterframe(){

    }

    updateEnvironment (envMap) {
        // this.traverseMaterials(this.container, (material) => {
        this.traverseMaterials(this.car, (material) => {

            // trace(material);
            if (material.isMeshStandardMaterial || material.isGLTFSpecularGlossinessMaterial) {
                material.envMap = envMap;
                material.needsUpdate = true;
            }
        });
    }

    traverseMaterials (object, callback) {
        object.traverse((node) => {
            if (!node.isMesh) return;
            const materials = Array.isArray(node.material)
                ? node.material
                : [node.material];
            materials.forEach(callback);
        });
    }

    updateObject(node, position, target){
        node.position.x = position.x;
        node.position.y = position.y;
        node.position.z = position.z;
        node.rotation.x = target.rotation.x;
        node.rotation.y = target.rotation.y;
        node.rotation.z = target.rotation.z;
    }

    tweenMaterialEmissive(emissive, color, delay, dr, ease){
        delay = delay | 0;
        dr = dr | 1;
        ease = ease | Quart.easeOut;
        TweenMax.to(emissive, dr, {delay:delay, r:color.r, g:color.g, b:color.b, ease:ease});
    }

    enterframeThinOut(){
        if(this.pack.header.isMenuOpen) return;
        if(!this.enabledUpdate) return;
        if(this.enabledOrbitControls) this.controls.update();
        if(!this.loaded) return;

        let delta = this.clock.getDelta();

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let mixer = this.animeList[i].mixer;
            let name = this.animeList[i].name;
            let action = anime.action;

            if (this.currentScene === 3) {
                if(name === "sensor") {
                    mixer.update(delta);
                    let opacity = (1 - (mixer.time % anime.duration / anime.duration)) * .7;    //opacity70%

                    for (let i = 0, len = this.sensorMeshMaterials.length; i < len; i++) {
                        let m = this.sensorMeshMaterials[i];
                        m.opacity = opacity;
                    }
                }
            }

            if(this.currentScene === 3){
                if(name.indexOf("carWire") > -1) {
                    mixer.update(delta);
                }
            }

            if(this.currentScene === 5){
                if(name === "defineUseCase") {
                    mixer.update(delta);
                }
            }

            if(this.currentScene === 6){
                if(name.indexOf("sixLayers") > -1) {
                    mixer.update(delta);
                }
            }

            if(this.currentScene === 7){
                if(name.indexOf("referenceDesign") > -1) {
                    mixer.update(delta);
                }
            }

            if(this.currentScene === 1 || this.currentScene === 2 || this.currentScene === 3){
                if(name === "car") {
                    mixer.update(delta);
                }
            }
        }

        //carアニメーション位置情報
        let carPosVector = new THREE.Vector3();
        carPosVector = this.currentCarPosVector = this.cameraTarget.getWorldPosition(carPosVector);

        //carWire位置アニメーション
        let carWireAdjustY = .8;
        let carWireVector = new THREE.Vector3();
        carWireVector = this.carWireTarget.getWorldPosition(carWireVector);
        carWireVector.y += carWireAdjustY;

        this.updateObject(this.carWire, carWireVector, this.carWireTarget);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let id = i + 1;
            let target = this["carWireTarget" + id];
            let mesh = this.carWireClones[i];
            let carWireVector = new THREE.Vector3();

            carWireVector = target.getWorldPosition(carWireVector);
            carWireVector.y += carWireAdjustY;

            this.updateObject(mesh, carWireVector, target);
        }

        //carアニメーション
        // if(this.carShadow.material.opacity < 1) this.carShadow.material.opacity = 1;        //なぜかopacityが0になってしまうので1にする
        // if(this.currentScene !== 3){
            this.updateObject(this.car, carPosVector, this.cameraTarget.parent);
            // trace(this.car.position);

            //carアニメーションに同期させる
            // this.updateObject(this.carShadow, carPosVector, this.cameraTarget.parent);
            // this.carShadow.position.y = this.carShadowY;
            this.updateObject(this.referenceDesignInterior, carPosVector, this.cameraTarget.parent);
            this.updateObject(this.referenceDesignRoof, carPosVector, this.cameraTarget.parent);
            this.updateObject(this.referenceDesignSensor, carPosVector, this.cameraTarget.parent);
            this.updateObject(this.sensor, carPosVector, this.cameraTarget.parent);
            // this.sensor.position.y = -0.1;
        // }

        this.carInterior.position.y = this.carInteriorDefY + this.referenceDesignInteriorTarget.position.y;
        this.carRoof.position.y = this.carRoofDefY + this.referenceDesignRoofTarget.position.y;
        this.carSensor.position.y = this.carSensorDefY + this.referenceDesignSensorTarget.position.y;

        let cameraTargetPosVector = new THREE.Vector3();
        cameraTargetPosVector = this.carCameraTarget.getWorldPosition(cameraTargetPosVector);

        let targetPositionRightNow = new THREE.Vector3();
        let targetLookAt;
        //カメラのcarの追従
        if(!this.enabledOrbitControls){
            // if(this.currentScene === 3){
            //     let cameraLookAtTargetPosVector = new THREE.Vector3();
            //     targetPositionRightNow.x = this.cameraTargetPosVectorForScene3.x;
            //     targetPositionRightNow.y = this.cameraTargetPosVectorForScene3.y;
            //     targetPositionRightNow.z = this.cameraTargetPosVectorForScene3.z;
            //     targetLookAt = this.cameraLookAtTargetPosVectorForScene3;
            // }else{
                let cameraLookAtTargetPosVector = new THREE.Vector3();
                cameraLookAtTargetPosVector = this.cameraLookAtTarget.getWorldPosition(cameraLookAtTargetPosVector);

                targetPositionRightNow.x = cameraTargetPosVector.x;
                targetPositionRightNow.y = cameraTargetPosVector.y;
                targetPositionRightNow.z = cameraTargetPosVector.z;
                targetLookAt = cameraLookAtTargetPosVector;
            // }
        }

        let ease = 15;
        let mouseX;
        let mouseY;

        if(this.currentScene === 1){
            mouseX = this.mouseStage.x / 150;
            mouseY = this.mouseStage.y / 180;
        }else if(this.currentScene === 2){
            mouseX = this.mouseStage.x / 150;
            mouseY = this.mouseStage.y / 450;
        }else if(this.currentScene === 3){
            mouseX = this.mouseStage.x / 50;
            mouseY = this.mouseStage.y / 50;
        }else if(this.currentScene === 4){
            mouseX = this.mouseStage.x / 870;
            mouseY = this.mouseStage.y / 870;
        }else if(this.currentScene === 5 || this.currentScene === 7){
            mouseX = this.mouseStage.x / 670;
            mouseY = this.mouseStage.y / 670;
        }else if(this.currentScene === 6){
            mouseX = this.mouseStage.x / 150;
            mouseY = this.mouseStage.y / 150;
        }else{
            mouseX = 0;
            mouseY = 0;
        }

        //for layout
        // mouseX = 0;
        // mouseY = 0;

        this.currentTargetPosition.x += (targetPositionRightNow.x - this.currentTargetPosition.x) / 3;
        this.currentTargetPosition.y += (targetPositionRightNow.y - this.currentTargetPosition.y) / 3;
        this.currentTargetPosition.z += (targetPositionRightNow.z - this.currentTargetPosition.z) / 3;

        this.camera.position.x += ((this.currentTargetPosition.x) - this.camera.position.x + mouseX) / ease;
        this.camera.position.y += ((this.currentTargetPosition.y) - this.camera.position.y + mouseY) / ease;
        this.camera.position.z += ((this.currentTargetPosition.z) - this.camera.position.z) / ease;

        this.currentLookatTargetPosition.x += (targetLookAt.x - this.currentLookatTargetPosition.x) / ease;
        this.currentLookatTargetPosition.y += (targetLookAt.y - this.currentLookatTargetPosition.y) / ease;
        this.currentLookatTargetPosition.z += (targetLookAt.z - this.currentLookatTargetPosition.z) / ease;

        this.camera.lookAt(this.currentLookatTargetPosition);
        // this.camera.lookAt(new THREE.Vector3(0,0,0));


        //car wheels
        if(this.currentScene < 4){
            this.wheelsDeg += this.currentWheelSpeed;
            let wheelsRad = this.pack.d2r(this.wheelsDeg);
            for( let i = 0, len = this.carTyres.length; i < len; i++ ){
                let mesh = this.carTyres[i];
                if(i === 0 || i === 2) mesh.rotation.x = wheelsRad;
                else mesh.rotation.x = -wheelsRad;
            }
        }

        this.render();
        if(this.enabledStats) this.stats.update();
    }

    executeResize() {
        super.executeResize();

        if(!this.canvas) return;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.canvas.width = this.width;
        this.canvas.height = this.height;

        if(!this.camera) return;
        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
    }
}