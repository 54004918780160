import {ua} from './libs/daijima/ua';

/*
以下3つの変数は環境変数から取得する
CONTENTFUL_API_BRANCH
CONTENTFUL_API_SPACES
CONTENTFUL_API_ACCESS_TOKEN
CONTACT_API_PATH
*/

export default class {
    constructor(target, targetPackageName) {
        this.init(target, targetPackageName);
        return this.obj;
    }

    getTargetPackage(){
        return this.obj;
    }

    init(target, targetPackageName){
        let obj = target[targetPackageName] = target[targetPackageName] || {};
        this.obj = obj;
        obj.BP = 768;
        obj.ua = ua;
        obj.hasTouch = ("ontouchstart" in window);
        obj.pageYOffset = 0;
        obj.pastPageYOffset = 0;
        obj.scrollWrapper = document.querySelector('.scroll-wrapper');

        obj.CONTACT_API_PATH = CONTACT_API_PATH;

        obj.CONTENTFUL_API_SPACES = CONTENTFUL_API_SPACES;
        obj.CONTENTFUL_API_ACCESS_TOKEN = CONTENTFUL_API_ACCESS_TOKEN;
        obj.CONTENTFUL_API_BRANCH = CONTENTFUL_API_BRANCH;
        obj.CONTENTFUL_API_PATH_LIST = [
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=meetOurTeam&limit=8&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                                  //Meet Our Team
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=ourPartners&limit=8&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                                  //Our Partner
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=developmentPlatform&order=fields.displayOrder&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                        //Development Platform
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=developmentPlatformDetails&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                           //Development Platform カルーセル内情報
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=operationPlatform&order=fields.displayOrder&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                          //Operation Platform
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=operationPlatformDetails&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                             //Operation Platform カルーセル内情報
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=contactInfo&order=fields.displayOrder&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                //Contact
        ];

        obj.RELEASE_NOTES_API_PATH = "/json_dev/recent.en.json";

        obj.getContentfulAPICareerPath = (id) => {
            return`https://cdn.contentful.com/spaces/` + obj.CONTENTFUL_API_SPACES + `/environments/` + obj.CONTENTFUL_API_BRANCH + `/entries?content_type=careerPositionEntry&fields.category.sys.contentType.sys.id=careerPositionCategory&fields.category.sys.id=${id}&access_token=` + obj.CONTENTFUL_API_ACCESS_TOKEN;
        };

        obj.getContentfulAPIMediaDetailPath = (id) => {
            return `https://cdn.contentful.com/spaces/` + obj.CONTENTFUL_API_SPACES + `/environments/` + obj.CONTENTFUL_API_BRANCH + `/entries?sys.id=${id}&access_token=` + obj.CONTENTFUL_API_ACCESS_TOKEN;
        };

        obj.getContentfulAPIImgAccessPath = (id) => {
            return `https://cdn.contentful.com/spaces/` + obj.CONTENTFUL_API_SPACES + `/environments/` + obj.CONTENTFUL_API_BRANCH + `/assets/${id}?access_token=` + obj.CONTENTFUL_API_ACCESS_TOKEN;
        };

        obj.apiJSONList;
        obj.LANG = document.documentElement.lang;        //ja or en

        obj.getName = (value) => {
            let name;
            if(obj.LANG === "en") {
                name = value.nameEn;
            }else if(obj.LANG === "ja") {
                name = value.nameJa;
            }else {
                name = value.nameCn;
            }
            return name;
        };

        obj.getLocation = (value) => {
            let location;
            if(obj.LANG === "en") {
                location = value.locationEn;
            }else if(obj.LANG === "ja") {
                location = value.locationJa;
            }else {
                location = value.locationCn;
            }
            return location;
        };

        obj.getPosition = (value) => {
            let position;
            if(obj.LANG === "en") {
                position = value.postionEn;
            }else if(obj.LANG === "ja") {
                position = value.postionJa;
            }else {
                position = value.postionCn;
            }
            return position;
        };

        obj.getTitle = (value) => {
            let title;
            if(obj.LANG === "en") {
                title = value.titleEn;
            }else if(obj.LANG === "ja") {
                title = value.titleJa;
            }else {
                title = value.titleCn;
            }
            return title;
        };

        obj.getBody = (value) => {
            let body;
            if(obj.LANG === "en") {
                body = value.bodyEn;
            }else if(obj.LANG === "ja") {
                body = value.bodyJa;
            }else {
                body = value.bodyCn;
            }
            return body;
        };

        obj.getDescription = (value) => {
            let description;
            if(obj.LANG === "en") {
                description = value.descriptionEn;
            }else {
                description = value.descriptionJa;
            }

            return description;
        };

        obj.getData = (value, name) => {
            let data;
            if(obj.LANG === "en") {
                data = value[name + "En"];
            }else {
                data = value[name + "Ja"];
            }

            return data;
        };


        obj.changeLang = (event) =>{
            let value = event.currentTarget.value;
            let href;
            if(obj.LANG === "en") href = location.href.split("/en")[1];
            if(obj.LANG === "cn") href = location.href.split("/cn")[1];
            else if(obj.LANG === "ja") href = location.pathname + location.search;

            if(value === "EN") href = "/en" + href;
            else if(value === "CN") href = "/cn"  + href;
            location.href = href;
        };

        obj.getFormatDate = (date) => {
            let dateArray = String(new Date(date)).split(" ");
            return dateArray[1] + " " + dateArray[2] + ", " + dateArray[3];
        };

        obj.setReleaseNotes = (container) => {
            let items = obj.apiJSONList[7].release_notes;
            let src = "";

            for( let i = 0, len = items.length; i < len; i++ ){
                let item = items[i];
                let html = obj.getReleaseNotesHTML(item);
                src += html;
            }

            container.innerHTML = src;
        };

        obj.getReleaseNotesHTML = (value) =>{
            let url = value.slug;
            let date = obj.getFormatDate(value.published_at);
            let product_name = value.title;
            let description = value.description;

            return `
                <li>
                    <a href="${url}" target="_blank">
                        <div class="li_child">
                            <div class="left">
                                <div class="date">${date}</div>
                            </div>
                            <div class="right">
                                <div class="product_name">${product_name}</div>
                                <p class="txt">${description}</p>
                            </div>
                        </div>
                    </a>
                </li>
            `;
        };

        // 3桁カンマ区切りとする
        obj.addComma = (num) => {
            var s = String(num).split('.');
            var ret = String(s[0]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            if (s.length > 1) {
                ret += '.' + s[1];
            }
            return ret;
        };

        obj.hex2RGB = ( h ) => {
            let r = Math.floor(h / 65536);
            let g = Math.floor( ( h % 65536) / 256);
            let b = h % 256;

            return {r:r, g:g, b:b};
        };

        obj.hex2NormalRGB = ( h ) => {
            let r = Math.floor(h / 65536);
            let g = Math.floor( ( h % 65536) / 256);
            let b = h % 256;

            return {r:r/255, g:g/255, b:b/255};
        };

        obj.d2r = function(d){
            return d * Math.PI / 180;
        };

        obj.r2d = function(r){
            return r * 180 / Math.PI;
        };

        obj.random = function(min, max){
            return Math.random() * (max - min) + min;
        };

        obj.checkBottom = () => {
            return (obj.pageYOffset + 1) >= (document.body.clientHeight - obj.common.sh);
        };

        obj.lockHTML = ()=>{
            let html = document.documentElement;
            html.classList.add("hidden");
        };

        obj.unlockHTML = ()=>{
            let html = document.documentElement;
            html.classList.remove("hidden");
        };

        obj.anchorScroll = function(targetY, dr, ease, cb){
            if(!dr) dr = 1;
            if(!ease) ease = Quart.easeInOut;
            let obj = {y:window.pageYOffset};
            TweenMax.killTweensOf(obj);

            TweenMax.to(obj, dr, {y:targetY, ease:ease, onUpdate:function(){
                window.scrollTo(0, obj.y);
            }, onComplete:function(){
                if(cb) cb();
            }});
        };

        obj.commonParallax = function(item, targetY, defY, addCode, moveY){
            let top = item.getBoundingClientRect().top;
            if(defY === undefined) defY = 200;

            let speed = (item.dataset.speed != "undefined")  ? item.dataset.speed : "5";
            let difY = (top - targetY) / speed + defY;  //スタート地点はデフォルトでは200px下げる
            if(addCode == undefined) addCode = '';

            if(moveY){
                if(difY < defY - moveY) difY = defY - moveY;
            }

            if(top < targetY) {
                item.style.transform = 'translate3d(0,' + difY + 'px, 0)' + addCode;
                // item.style.top = difY + 'px';
            }
        };

        obj.addZero = function(str){
            if(str.length == 1) str = "0"+str;
            return str;
        };
    }
}
